<template>
  <div class="content">
    <Stext size="6" weight="700" align="center" ele="h1" top="50" bottom="20">{{
      data.title
    }}</Stext>
    <div v-for="(item, index) in data.content" :key="index">
      <h2
        style="
          margin-top: 30px;
          margin-bottom: 30px;
          font-size: 16pt;
          font-weight: 800;
          color: #333;
        "
      >
        {{ item.title }}
      </h2>

      <template v-if="index == 0">
        <Stext size="9" ele="p" top="5" indent="true">{{ item.content }}</Stext>
      </template>
      <template v-if="index == 1">
        <ele :data="item.content.chart"></ele>
        <Stext size="9" ele="p" top="5" indent="true">{{
          item.content.note
        }}</Stext>
        <Stext
          v-for="desc in item.content.desc"
          :key="desc"
          size="9"
          ele="p"
          top="5"
          indent="true"
          >{{ desc }}</Stext
        >
      </template>
      <template v-if="index == 2">
        <div v-for="(val, k) in item.content" :key="k">
          <Stext size="9" ele="p" top="5" indent="true">{{ val.title }}</Stext>
          <Stext
            v-for="desc in val.content.detail"
            :key="desc"
            size="9"
            ele="p"
            top="5"
            indent="true"
            >{{ desc }}</Stext
          >

          <fourTable
            class="mt-3"
            v-if="k == 0"
            :data="getTable1(val.content.盈利能力)"
          ></fourTable>
          <Stext
            v-if="k == 1"
            size="4"
            ele="p"
            top="10"
            align="center"
            indent="true"
            >短期偿债能力</Stext
          >
          <fourTable
            class="mt-2"
            v-if="k == 1"
            :data="getTable1(val.content.短期偿债能力)"
          ></fourTable>
          <Stext
            v-if="k == 1"
            size="4"
            ele="p"
            top="10"
            align="center"
            indent="true"
            >长期偿债能力</Stext
          >
          <fourTable
            class="mt-2"
            v-if="k == 1"
            :data="getTable1(val.content.长期偿债能力)"
          ></fourTable>
          <fourTable
            class="mt-3"
            v-if="k == 2"
            :data="getTable1(val.content.营运能力)"
          ></fourTable>
          <fourTable
            class="mt-3"
            v-if="k == 3"
            :data="getTable1(val.content.发展能力)"
          ></fourTable>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Stext from "./text.vue";
import STable from "./table.vue";
import fourTable from "./fourTable.vue";
import ele from "./ele.vue";
export default {
  components: {
    Stext,
    STable,
    fourTable,
    ele,
  },
  props: {
    data: Object,
  },
  data() {
    return {};
  },
  mounted() {},
  watch: {},
  created() {},
  updated() {},
  methods: {
    getTable(table) {
      let header = [
        [
          { val: table[0][0], colspan: 1, rowspan: 2 },
          { val: table[0][1], colspan: 3, rowspan: 1 },
          { val: table[0][2], colspan: 3, rowspan: 1 },
        ],
        [
          { val: "金额" },
          { val: "占比（%）" },
          { val: "变动率（%）" },
          { val: "金额" },
          { val: "占比（%）" },
          { val: "变动率（%）" },
        ],
      ];
      let body = [];
      table.map((item, i) => {
        if (i !== 0) {
          body.push(item);
        }
      });

      return {
        header,
        body,
      };
    },

    getTable1(table) {
      let header = [
        { val: "指标名称" },
        { val: "本期指标值" },
        { val: "行业优秀值" },
        { val: "行业平均值" },
        { val: "行业较差值" },
        { val: "变动率" },
      ];

      let name = Object.keys(table);
      let body = Object.values(table);
      body.forEach((item, index) => {
        item.name = name[index];
      });

      return {
        header,
        body,
      };
    },
    getTable2(table) {
      let header = [
        { val: "指标名称" },
        { val: "本期指标值" },
        { val: "行业优秀值" },
        { val: "行业平均值" },
        { val: "行业较差值" },
        { val: "变动率" },
      ];

      let name = Object.keys(table);
      let body = Object.values(table);
      body.forEach((item, index) => {
        item.name = name[index];
      });

      return {
        header,
        body,
      };
    },
  },
  computed: {},
};
</script>

<style lang="less" scoped></style>
