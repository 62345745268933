<template>
  <div class="content">
    <Stext size="2" ele="h1" align="center" weight="800" top="60" bottom="50">
      {{data.title1}}
    </Stext>
    <div v-for="(item, index) in data.content" :key="index">
      <template v-if="index == 0">
        <h2 style="font-size: 12pt;font-weight: 800;color: #000;margin-top: 20px;">
          {{ item.title2 }}
        </h2>
        <div style="margin-top: 10px; font-size: 10.5pt; font-family: 'SimSun'">
          <p>
            <span>指标波动幅度标识：</span><br />
            <span>
              用符号“
              <img width="14" height="14" :src="img.icon03" style="margin-top: 3pt; margin-right: 2pt"/>
              <img width="14" height="14" :src="img.icon03" style="margin-top: 3pt; margin-right: 2pt"/>
              <img width="14" height="14" :src="img.icon07" style="margin-top: 3pt; margin-right: 2pt"/>
              <img width="14" height="14" :src="img.icon07" style="margin-top: 3pt; margin-right: 2pt"/>
              <span style="margin-left: 16px"></span
              >”来表示波动大于60%的指标及变化方向；<br />
              用符号“
              <img width="14" height="14" :src="img.icon04" style="margin-top: 3pt; margin-right: 2pt"/>
              <img width="14" height="14" :src="img.icon06" style="margin-top: 3pt; margin-right: 2pt"/>
              ”来表示 波动处于30%-60%之间的指标及变化方向。
            </span>
          </p>
        </div>
        <Stext ele="p" align="right" bottom="10">(单位：万元、%)</Stext>
        <STable :showLine="true" :data="getOneTableData(item.content)"></STable>
      </template>
      <template v-if="index == 1">
        <h2
          style="
            font-size: 12pt;
            font-weight: 800;
            color: #000;
            margin-top: 20px;
          "
        >
          {{ item.title2 }}
        </h2>
        <div style="margin-top: 10px; font-size: 10.5pt; font-family: 'SimSun'">
          <p>
            指标行业所处地位：
            <span style="color: #10ba34">■高于行业优秀值</span>、
            <span>■处于行业优秀值和较差值之间</span>、
            <span style="color: #f72222">■低于行业较差值</span><br />
            <span>指标波动幅度标识：</span><br />
            <span>
              用符号“
              <img width="14" height="14" :src="img.icon03" style="margin-top: 3pt; margin-right: 2pt"/>
              <img width="14" height="14" :src="img.icon03" style="margin-top: 3pt; margin-right: 2pt"/>
              <img width="14" height="14" :src="img.icon07" style="margin-top: 3pt; margin-right: 2pt"/>
              <img width="14" height="14" :src="img.icon07" style="margin-top: 3pt; margin-right: 2pt"/>
              <span style="margin-left: 16px"></span>”来表示波动大于60%的指标及变化方向；<br />用符号“
              <img width="14" height="14" :src="img.icon04" style="margin-top: 3pt; margin-right: 2pt"/>
              <img width="14" height="14" :src="img.icon06" style="margin-top: 3pt; margin-right: 2pt"/>
              ”来表示 波动处于30%-60%之间的指标及变化方向。
            </span>
          </p>
        </div>
        <STable
          :showLine="true"
          :data="getTwoTableData(item.content[0])"
        ></STable>
      </template>
    </div>
  </div>
</template>
<script>
import Stext from "./text.vue";
import STable from "./table.vue";
import icon03 from "../../img/03.png";
import icon04 from "../../img/04.png";

import icon06 from "../../img/06.png";
import icon07 from "../../img/07.png";
export default {
  components: {
    Stext,
    STable,
  },
  props: {
    data: Array,
  },
  data() {
    return {
      img: {
        icon03: icon03,
        icon04: icon04,
        icon06: icon06,
        icon07: icon07,
      },
    };
  },
  computed: {},
  methods: {
    getTwoTableData(table) {
      const header = this.getHeader(table);
      const body = this.getBody(table);
      // const body = [];
      return {
        header,
        body,
      };
    },
    getOneTableData(table) {
      let newTable = {
        body: [],
        header: [],
      };
      let num = 1;
      table.forEach((item, index) => {
        if (index == 0) {
          item.content.forEach((ele, i) => {
            if (i == 0) {
              newTable.header[0] = [];
              ele.forEach((val, i) => {
                newTable.header[0].push({
                  colspan: 1,
                  rowspan: 1,
                  style: "text-align: center;",
                  val,
                });
                delete newTable.header[0][0].style
              });
              newTable.body[0] = [];
              newTable.body[0].push({
                colspan: 1,
                rowspan: 1,
                style: "text-align: center;",
                val: item.fs_name,
              });
            } else {
              newTable.body[num] = [];
              ele.forEach((val) => {
                if (typeof val == "object") {
                  newTable.body[num].push({
                    colspan: 1,
                    rowspan: 1,
                    style: "text-align: right;",
                    status: val[2]+3,
                    val: val[0] + "%",
                  });
                delete newTable.body[num][0].style
                
                } else {
                  newTable.body[num].push({
                    colspan: 1,
                    rowspan: 1,
                    val,
                    style: "text-align: right;",
                  });
                  delete newTable.body[num][0].style
                }
              });
              num += 1;
            }
          });
        } else {
          item.content.forEach((ele, i) => {
            if (i == 0) {
              newTable.body[num] = [];
              newTable.body[num].push({
                colspan: 1,
                rowspan: 1,
                style: "text-align: right;",
                val: item.fs_name,
              });
              delete newTable.body[num][0].style
              num += 1;
            } else {
              newTable.body[num] = [];
              ele.forEach((val) => {
                if (typeof val == "object") {
                  newTable.body[num].push({
                    colspan: 1,
                    rowspan: 1,
                    style: "text-align: right;",
                    status: val[2]+3,
                    val: val[0] + "%",
                  });
                  delete newTable.body[num][0].style
                } else {
                  newTable.body[num].push({
                    colspan: 1,
                    rowspan: 1,
                    val,
                    style: "text-align: right;",
                  });
                  delete newTable.body[num][0].style
                }
              });
              num += 1;
            }
          });
        }
      });
      return this.getTableData(newTable);
    },
    getTableData(table) {
      table.body.map((item, i) => {
        if (item.length == 1) {
          item[0].colspan = table.body[i + 1].length;
          item[0].style += "text-align: left;background: #d7d7d7;";
        }
      });
      return table;
    },
    getRowspan(item) {
      const arr = ["指标值", "变动率"];
      const returnArr = [];
      Object.keys(item).some((key) => {
        if (arr.includes(key)) returnArr.push({ val: key,style: "text-align: center", });
      });

      return returnArr;
    },
    getHeader(table) {
      let header = [
        { rowspan: 2, val: "指标大类" ,style: "text-align: left",},
        { val: "指标名称", rowspan: 2,style: "text-align: left", },
      ];
      let header2 = [];
      table.some((item_power) => {
        Object.values(item_power).some((item) => {
          Object.values(item).some((item) => {
            Object.values(item).some((item) => {
              item.map((item) => {
                for (const key in item) {
                  const nameArr = this.getRowspan(item[key]);
                  header2 = header2.concat(nameArr);
                  header.push({
                    val: key,
                    colspan: nameArr.length,
                    style:"text-align:center"
                  });
                }
              });
            });
            return true;
          });
          return true;
        });
        return true;
      });
      return [header, header2];
    },
    getBody(table) {
      const rule_analysis_data = [];

      table.some((item_power) => {
        Object.values(item_power).some((item) => {
          const powerArr = item;
          item.some((item, i) => {
            const row = [];
            const name = Object.keys(item_power)[0];

            const powerItem = {
              val: name,
              rowspan: powerArr.length,
            };

            if (0 == i) row.push(powerItem);
            row.push({ val: Object.keys(item)[0] });

            Object.values(item).map((item) => {
              item.map((item) => {
                const oneItem = Object.values(item)[0];
                const 指标值 = {
                  val: oneItem.指标值,
                  status: oneItem.flag < 0 ? 1 : oneItem.flag,
                  style: "text-align: right;",
                };
                const 变动率 = {
                  val: oneItem.变动率,
                  status:
                    oneItem.increase_flag < 0 ? 1 : 3 + oneItem.increase_flag,
                  style: "text-align: right;",
                };
                row.push(指标值);
                if (oneItem.变动率) row.push(变动率);
              });
            });
            rule_analysis_data.push(row);
          });
        });
      });
      return rule_analysis_data;
    },
  },
};
</script>
