<template>
  <h1 v-if="ele == 'h1'" :style="getStyle">
    <slot></slot>
  </h1>
  <p v-else :style="getStyle">
    <slot></slot>
  </p>
</template>
<script>
export default {
  props: {
    ele: {
      type: String,
      default: "h1",
    },
    style: String,
    align: {
      type: String,
      default: "left",
    },
    size: {
      type: String,
      default: "7",
    },
    color: {
      type: String,
      default: "#000",
    },
    top: {
      type: String,
      default: "0",
    },
    bottom: {
      type: String,
      default: "0",
    },
    border: {
      type: String,
    },
    weight: String,
    indent: String,
  },
  data() {
    return {
      sizes: {
        1: 36,
        2: 16,
        3: 20,
        4: 12,
        5: 22,
        6: 18,
        7: 12,
        8: 24,
        9: 14,
        10: 10,
      },
    };
  },
  computed: {
    getStyle() {
      const style =
        (this.indent ? `text-indent:${this.sizes[this.size] * 2}pt;` : "") +
        `text-align: ${this.align};font-size: ${
          this.sizes[this.size]
        }pt;line-height: 24pt;color: ${this.color};margin-top: ${
          this.top
        }px;margin-bottom: ${this.bottom}px;
        font-weight: ${this.weight};`;

      return style;
    },
  },
  methods: {},
};
</script>
