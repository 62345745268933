<template>
  <div class="content">
    <Stext size="6" align="center" weight="700" ele="h1" top="50" bottom="20">{{
      data.title
    }}</Stext>

    <div v-for="(item, index) in data.content" :key="index">
      <Stext v-if="index != 3" size="9" ele="p" top="5" indent="true">{{
        item
      }}</Stext>

      <div v-else>
        <STable
          style="margin-top: 20px"
          :data="getTable(data.content[3].content, data.content[3].title2)"
        ></STable>
      </div>
    </div>
  </div>
</template>

<script>
import Stext from "./text.vue";
import STable from "./twoTable";
import { whitewash_list_filter_data } from "./filter";
export default {
  components: {
    Stext,
    STable,
  },
  props: {
    data: Object,
  },
  methods: {
    getTable(table, table_name) {
      const header = [
        [
          {
            val: table_name,
            style: "font-weight: 700;",
            colspan: "3",
            style: "text-align:center",
          },
        ],
        [
          { val: "异常指标", style: "background: #fff;" },
          { val: "异常详情及异常描述", style: "background: #fff;" },
          { val: "建议", style: "background: #fff;" },
        ],
      ];
      const body = [];

      const data = [];

      Object.values(table).some((item, i) => {
        const filterData = whitewash_list_filter_data(item);

        filterData && data.push(filterData);
      });
      let index = 0;
      data.map((items) => {
        items.table_data.map((item, i) => {
          let ind = index;
          body[ind] = [];
          body[ind].push({
            val: item.second_index,
            rowspan: item.result_desc.length,
          });
          item.result_desc.map((el, num) => {
            if (num == 0) {
              body[ind].push({ val: el });
            } else {
              index++;
              body[index] = [];
              body[index].push({ val: el });
            }
          });
          body[ind].push({
            val: items.table_data[i].suggestion,
            rowspan: item.result_desc.length,
          });
          index++;
        });
      });

      return {
        header,
        body,
      };
    },
  },
};
</script>

<style></style>
