<template>
  <div class="content">
    <div v-for="(item, index) in first" :key="index">
      <div v-if="index == 0">
        <Stext
          v-for="title in item.cover"
          :key="title"
          size="3"
          ele="h1"
          align="center"
          top="50"
          >{{ title }}</Stext
        >
      </div>

      <Stext
        v-if="index !== 4"
        size="6"
        weight="700"
        ele="p"
        top="50"
        bottom="20"
        >{{ item.title }}</Stext
      >
      <Stext
        v-if="index == 4"
        size="2"
        weight="700"
        ele="p"
        top="50"
        bottom="20"
        >{{ item.title }}</Stext
      >
      <div v-if="index == 1 || index == 2">
        <p
          style="font-size: 14pt; line-height: 24pt; color: black"
          v-for="text in item.content"
          :key="text"
          v-html="text"
        ></p>
      </div>
      <div v-if="index == 3">
        <div v-for="(text, i) in item.content" :key="i">
          <p
            v-if="i == 0"
            style="font-size: 14pt; line-height: 24pt; color: black"
            v-html="text"
          ></p>
          <STable
            v-else
            style="margin-top: 20px"
            :data="getTable(text.content, text.title)"
          ></STable>
        </div>
      </div>
      <div v-if="index == 4">
        <p
          style="font-size: 12pt; line-height: 24pt; color: black"
          v-for="text in item.content"
          :key="text"
          v-html="text"
        ></p>
      </div>
    </div>
  </div>
</template>

<script>
import Stext from "./text.vue";
import STable from "./table.vue";
import { whitewash_list_filter_data } from "./filter.js";

export default {
  components: {
    Stext,
    STable,
  },
  props: {
    first: Object,
  },
  methods: {
    getTable(table, table_name) {
      const header = [
        [
          {
            val: table_name,
            style: "font-weight: 700;",
            colspan: "3",
            style: "text-align:center",
          },
        ],
        [
          { val: "异常指标", style: "background: #fff;" },
          { val: "异常详情及异常描述", style: "background: #fff;" },
          { val: "建议", style: "background: #fff;" },
        ],
      ];
      const body = [];

      const data = [];

      Object.values(table).some((item, i) => {
        const filterData = whitewash_list_filter_data(item);
        filterData && data.push(filterData);
      });
      let index = 0;
      data.map((items) => {
        items.table_data.map((item, i) => {
          let ind = index;
          body[ind] = [];
          body[ind].push({
            val: item.second_index,
            rowspan: item.result_desc.length,
          });
          item.result_desc.map((el, num) => {
            if (num == 0) {
              body[ind].push({ val: el });
            } else {
              index++;
              body[index] = [];
              body[index].push({ val: el });
            }
          });
          body[ind].push({
            val: items.table_data[i].suggestion,
            rowspan: item.result_desc.length,
          });
          index++;
        });
      });

      return {
        header,
        body,
      };
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  p {
    line-height: 1.7;
  }
}
</style>
