<template>
  <table
    border="1"
    style="
      width: 100%;
      font-size: 14pt;
      color: #333;
      background-color: transparent;
      border-collapse: collapse;
    "
  >
    <thead>
      <tr>
        <th
          style="background: #c7daf1"
          class="th"
          v-for="(tr, i) in data.header"
          :key="i"
        >
          {{ tr.val }}
        </th>
      </tr>
    </thead>

    <tbody>
      <tr v-for="(tr, i) in data.body" :key="i">
        <td
          style="
            padding: 5px;
            border: 1px solid #333;
            text-align: left;
            width: 100px;
          "
        >
          {{ tr.name }}
        </td>
        <td
          style="
            padding: 5px;
            border: 1px solid #333;
            text-align: center;
            width: 100px;
          "
        >
          <span v-show="tr.rlevel == 1">提示信息</span>
          <span v-show="tr.rlevel == 2">需要关注</span>
          <span v-show="tr.rlevel == 3">重点关注</span>
        </td>
        <td style="padding: 5px; border: 1px solid #333; text-align: left">
          {{ tr.brief }}
        </td>
        <td style="padding: 5px; border: 1px solid #333; text-align: left">
          {{ tr.desc }}
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  components: {},
  props: {
    data: Array,
    borderColor: {
      type: String,
      default: "#000",
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped>
.th {
  padding: 5px;
  border: 1px solid #333;
  font-weight: bold;
  background: #c7daf1;
  vertical-align: middle;
  text-align: center;
}
</style>
