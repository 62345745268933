<template>
  <div class="content">
    <Stext size="2" ele="h1" align="center" weight="800" top="60" bottom="50">{{
      data.title1
    }}</Stext>
    <Stext
      v-for="item in data.details[0].content"
      :key="item"
      size="10"
      ele="p"
      indent="true"
      bottom="20"
      >{{ item }}
    </Stext>
    <div class="row" id="pointTag">
      <Stext size="9" ele="p" bottom="20">重点关注：</Stext>
      <Stext
        size="10"
        ele="p"
        bottom="20"
        top="4"
        v-if="data.details[1].content.length == 0"
      >
        无
      </Stext>
      <div v-else>
        <Stext
          size="10"
          ele="p"
          bottom="20"
          top="4"
          v-for="item in data.details[1].content"
          :key="item"
        >
          {{ item[1] }}、
        </Stext>
      </div>

      <!-- <div class="row">
        <div v-for="item in data.details[1].content" :key="item" class="tagBox">
          {{ item[1] }}
        </div>
      </div> -->
    </div>
    <Stext size="10" ele="p" bottom="20">{{ data.details[2].content }}</Stext>
    <table
      border="1"
      style="
        width: 100%;
        background-color: transparent;
        border-collapse: collapse;
      "
    >
      <thead>
        <tr v-for="tr in header" :key="tr">
          <th
            v-for="th in tr"
            :key="th"
            :colspan="th.colspan || 1"
            :rowspan="th.rowspan || 1"
            :style="
              `padding: 5px;border:1px solid ${borderColor}; font-weight:bold; background: #c7daf1;vertical-align: middle;` +
              th.style
            "
          >
            <s-text
              ele="p"
              size="10"
              weigth="700"
              :style="'text-align: left;color:#333;font-size:14px;'"
              >{{ th.val }}</s-text
            >
          </th>
        </tr>
      </thead>
      <tbody
        v-for="(item, index) in getTable(data.details[3].content.detail)"
        :key="index"
      >
        <tr v-for="(tr, i) in item" :key="i">
          <td
            v-for="(th, k) in tr"
            :key="k"
            :style="`text-align: left;color:#333;font-size:14px;padding: 5px;border:1px solid ${borderColor};vertical-align: middle;;padding: 5px;border:1px solid ${borderColor}`"
            :width="th.width"
          >
            <div v-if="k == 1">
              <span v-show="th == '1'">提示信息</span>
              <span v-show="th == '2'">需要关注</span>
              <span v-show="th == '3'">重点关注</span>
            </div>
            <span v-else>
              {{ th }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import * as sesstion from "@/store/localStorage";
import Stext from "./text.vue";
// import STable from "./table";

import html2canvas from "html2canvas";
export default {
  components: {
    Stext,
    // STable,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      unitText: "万",
      RateArray: {},
      seriers: {},
      imngCenter: false,
      dataList: [],
      list: [],
      header: [
        [
          {
            val: "风险类型",
            style: "font-weight: 700;text-align:left;width:15%",
          },
          {
            val: "风险等级",
            style: "font-weight: 700;text-align:left;width:10%",
          },
          {
            val: "关注点",
            style: "font-weight: 700;text-align:left;;width:25%",
          },
          {
            val: "风险提示",
            style: "font-weight: 700;text-align:left;;width:50%",
          },
        ],
      ],
      borderColor: "#000",
      showLine: {
        type: Boolean,
        default: false,
      },
    };
  },
  created() {},
  updated() {},
  mounted() {},
  watch: {},
  methods: {
    getTable(table) {
      // if (this.$route.query.status == 2) {
      //   setTimeout(() => {
      //     this.toImgWhitewash();
      //   }, 1500);
      // }
      const body = [];
      for (let index in table) {
        for (let i in table[index]) {
          body.push([
            table[index][i].kind,
            table[index][i].rlevel,
            table[index][i].name,
            table[index][i].desc,
          ]);
        }
      }
      return {
        body,
      };
    },
    toImgWhitewash() {
      if (this.$route.query.status == 0) return false;
      const Whitewash = document.querySelector("#pointTag");
      html2canvas(Whitewash).then(function (canvas) {
        let pageData = canvas.toDataURL("image/jpeg");
        let img = new Image();
        img.src = pageData;
        img.width = "550";
        img.height = "50";
        Whitewash.innerHTML = "";
        Whitewash.appendChild(img);
      });
    },
  },
  computed: {},
};
</script>

<style lang="less" scoped>
.tagBox {
  // background: #f7dcdc;
  // padding: 5px;
  // height: 25px;
  font-size: 14px;
  line-height: 16px;
  color: #333;
  // color: #f76b6b;
  // border-radius: 5px;
  // margin-right: 10px;
  // margin-bottom: 10px;
}
</style>
