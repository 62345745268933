<template>
  <div>
    <div ref="ele" style="width: 100%; height: 350px"></div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
  },
  watch: {
    // data() {
    //   this.init();
    // },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const title = [];
      const data = [];
      if (this.data && this.data.length) {
        for (var i = this.data.length - 1; i >= 0; i--) {
          var row = this.data[i];
          // console.log(row);
          title.push(row.name);
          data.push(100 - row.pos_val);
        }
      }
      var option = {
        legend: {
          selectedMode: false,
          type: "plain", // 普通图例
          orient: "horizontal", // 水平
          data: [
            "排名前0-10%",
            "排名前10-32.5%",
            "排名前32.5-67.5%",
            "排名前67.5-90%",
            "排名前90-100%",
          ],
          bottom: 20,
        },
        // 位置
        grid: {
          top: "5%",
          bottom: "8%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          show: true,
          min: 0,
          max: 100,
          // minInterval:10,
          // maxInterval:32.5,
          interval: 10,
          axisLine: {
            show: true,
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: [
                "#ccc",
                "#ccc",
                "#FFF",
                "#ccc",
                "#FFF",
                "#FFF",
                "#ccc",
                "#FFF",
                "#FFF",
                "#ccc",
                "#ccc",
              ],
            },
          },
          axisLabel: {
            formatter: function (value, i) {
              // console.log(value,i);
              var texts = [];
              if (i == 1) {
                texts.push("{LabelStyle1|\n\n排名前\n90 - 100%}");
              } else if (i == 2) {
                texts.push("{LabelStyle2|\n\n排名前\n67.5 - 90%}");
              } else if (i == 5) {
                texts.push("{LabelStyle3|\n\n排名前\n32.5 - 67.5%}");
              } else if (i == 8) {
                texts.push("{LabelStyle4|\n\n排名前\n10 - 32.5%}");
              } else if (i == 10) {
                texts.push("{LabelStyle1|\n\n排名前\n0 - 10%}");
              }
              return texts;
            },
            rotate: 0.1,
            // padding: 10,
            rich: {
              LabelStyle1: {
                padding: 10,
                align: "center",
                lineHeight: 15,
              },
              LabelStyle2: {
                padding: -20,
                align: "center",
                lineHeight: 15,
              },
              LabelStyle3: {
                padding: 2,
                align: "center",
                lineHeight: 15,
              },
              LabelStyle4: {
                padding: 2,
                align: "center",
                lineHeight: 15,
              },
            },
          },
        },
        yAxis: {
          type: "category",
          data: title,
          axisLine: {
            show: true,
          },
        },
        series: [
          {
            name: "排名前0-10%",
            type: "bar",
            barWidth: "50%",
            data: data,
            color: "#529EE2",
            itemStyle: {
              normal: {
                color: function (params) {
                  if (params.data <= 10) {
                    return "#F2637B";
                  }
                  if (params.data <= 32.5) {
                    return "#F88E36";
                  }
                  if (params.data > 32.5 && params.data <= 67.5) {
                    return " #FAD33F";
                  }
                  if (params.data <= 90) {
                    return "#3EBB9F";
                  }
                  if (params.data <= 100) {
                    return "#529EE2";
                  }
                },
              },
            },
          },
          {
            name: "排名前10-32.5%",
            type: "bar",
            color: "#3EBB9F",
            barWidth: "0%",
          },
          {
            name: "排名前32.5-67.5%",
            barWidth: "0%",
            type: "bar",
            color: "#FAD33F",
          },
          {
            name: "排名前67.5-90%",
            barWidth: "0%",
            type: "bar",
            color: "#F88E36",
          },
          {
            name: "排名前90-100%",
            barWidth: "0%",
            type: "bar",
            color: "#F2637B",
          },
        ],
      };

      this.$echarts.init(this.$refs.ele).setOption(option);
      if (this.$route.query.status == 2) {
        setTimeout(() => {
          this.toImg();
        }, 1500);
      }
    },
    toImg() {
      const base64 = this.$refs.ele.querySelector("canvas").toDataURL();
      this.$refs.ele.innerHTML = `<img width="600" height="250" src="${base64}" / >`;
    },
  },
};
</script>

<style>
</style>