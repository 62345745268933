<template>
  <div class="content mt-3">
    <h1 style="font-size: 16pt; font-weight: 800; color: #000">附录</h1>
    <!-- <div>
      <h2
        style="font-size: 12pt; font-weight: 800; color: #000; margin-top: 10px"
      >
        1、综合评级
      </h2>
      <s-text ele="p" size="10" top="10" indent="true">
        根据每年每个行业中企业的财务粉饰程度和财务风险的综合情况，进行系统内财务状况总评级的排序，评级区间如下：
      </s-text>
      <div style="margin: 15pt 0" ele="p">
        <table
          border="1"
          style="
            text-align: left;
            width: 100%;
            max-width: 100%;
            margin-bottom: 1rem;
            background-color: transparent;
            border-collapse: collapse;
            font-size: 14pt;
          "
        >
          <thead>
           
            <tr>
              <td
                style="
                  font-weight: 700;
                  padding: 5pt;
                  font-size: 10.5pt;
                  border: 1pt solid #000;
                  background: #c7daf1;
                  vertical-align: middle;
                  text-align: center;
                "
              >
                评级含义：
              </td>
              <td
                style="
                  font-weight: 700;
                  padding: 5pt;
                  font-size: 10.5pt;
                  border: 1pt solid #000;
                  vertical-align: middle;
                  text-align: center;
                "
              >
                优秀
              </td>
              <td
                style="
                  font-weight: 700;
                  padding: 5pt;
                  font-size: 10.5pt;
                  border: 1pt solid #000;
                  vertical-align: middle;
                  text-align: center;
                "
              >
                良好
              </td>
              <td
                style="
                  font-weight: 700;
                  padding: 5pt;
                  font-size: 10.5pt;
                  border: 1pt solid #000;
                  vertical-align: middle;
                  text-align: center;
                "
              >
                一般
              </td>
              <td
                style="
                  font-weight: 700;
                  padding: 5pt;
                  font-size: 10.5pt;
                  border: 1pt solid #000;
                  vertical-align: middle;
                  text-align: center;
                "
              >
                较差
              </td>
              <td
                style="
                  font-weight: 700;
                  padding: 5pt;
                  font-size: 10.5pt;
                  border: 1pt solid #000;
                  vertical-align: middle;
                  text-align: center;
                "
              >
                很差
              </td>
            </tr>
          </thead>
        </table>
        <s-text
          ele="p"
          size="10"
          top="10"
          indent="true"
          v-for="(item, i) in textList1"
          :key="i"
        >
          <span
            ><b>{{ item.name }}</b></span
          >
          <span>{{ item.text }}</span>
        </s-text>
      </div>
    </div> -->
    <div>
      <h2
        style="font-size: 12pt; margin-top: 10px; font-weight: 800; color: #000"
      >
        1、财务粉饰
      </h2>
      <s-text ele="p" size="10" top="10" indent="true">
        根据专家粉饰规则及模型评判出财报粉饰的程度。财务粉饰程度区间分为：基本可信，少量存疑和明显异常。
      </s-text>
      <s-text
        ele="p"
        size="10"
        top="10"
        indent="true"
        v-for="(item, i) in textList2"
        :key="i"
      >
        <span
          ><b>{{ item.name }}</b></span
        >
        <span>{{ item.text }} </span>
      </s-text>
    </div>
    <div>
      <h2
        style="font-size: 12pt; font-weight: 800; margin-top: 10px; color: #000"
      >
        2、财务能力
      </h2>
      <s-text ele="p" size="10" top="10" indent="true"
        >财务能力是从企业五大财务能力出发，对其相关的能力进行评价分析，将目标企业的财务能力分为五档，即：</s-text
      >
      <s-text ele="p" size="10" top="10" indent="true"
        >能力低、能力较低、能力中、能力较高、能力高。</s-text
      >
    </div>
    <div>
      <h2
        style="font-size: 12pt; font-weight: 800; margin-top: 10px; color: #000"
      >
        3、财务风险
      </h2>
      <s-text ele="p" size="10" top="10" indent="true"
        >财务风险是从企业财务状况整体能力出发，对其相关的风险进行评价分析，将目标企业的财务能力分为三档，即：</s-text
      >
      <s-text ele="p" size="10" top="10" indent="true"
        >风险低，风险中，风险高</s-text
      >
    </div>
    <div>
      <h2
        style="font-size: 12pt; font-weight: 800; margin-top: 10px; color: #000"
      >
        4、当期报表分析
      </h2>
      <s-text ele="p" size="10" top="10" indent="true">
        本报告原则上是以三年为一个区间对企业的年度财务报表进行分析（最少需要一年及一期的报表才能分析）。对于非年报且没有上年同期报表时，我们采用年化的方式，对该期报表年化处理后再与上年度报表进行对比分析。总体上，我们建议用户首选年度报表进行分析评价，单期报表的分析报告做为辅助。
      </s-text>
    </div>
  </div>
</template>
<script>
import SText from "./text";
import STable from "./table";
import SPaging from "./Paging/index";
export default {
  components: {
    SText,
    STable,
    SPaging,
  },
  data() {
    return {
      textList1: [
        {
          name: "√ 优秀或良好：",
          text: "说明企业财务状况优秀或良好，智能报表分析结果对企业授信评级无负面影响，可以适当放宽授信",
        },
        {
          name: "√ 一般：",
          text: "说明企业财务状况一般，智能报表分析结果对企业授信评级无负面影响。",
        },
        {
          name: "√ 较差：",
          text: "说明企业财务状况较差，智能报表分析结果对企业授信评级存在负面影响。",
        },
        {
          name: "√ 很差：",
          text: "说明企业财务状况很差，智能报表分析结果对企业授信评级存在负面影响，总体上不建议进行授信。",
        },
      ],
      textList2: [
        {
          name: "√ 基本可信：",
          text: "在财报粉饰粉饰分析中，没有发现报表中存在若干或大量或重大的异常情况，说明企业提供的报表为基本可信状态。 ",
        },
        {
          name: "√ 少量存疑：",
          text: "在财报粉饰粉饰分析中，发现报表中存在若干异常情况（没到非常严重程度），建议要求企业进一步提供补充信息来解释相关的异常情况，从而进一步提高报表的真实可信程度。",
        },
        {
          name: "√ 明显异常：",
          text: "在财报粉饰粉饰分析中，发现报表中存在大量或重大的异常情况，需要要求企业进一步提供补充信息来解释相关异常情况，从而进一步判断报表的真实性。",
        },
      ],
    };
  },
  computed: {},
};
</script>
