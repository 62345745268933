<template>
  <div class="content">
    <Stext size="6" align="center" weight="700" ele="h1" top="50" bottom="20">{{
      data.title
    }}</Stext>
    <div v-for="(item, key, index) in data.content" :key="index">
      <h2
        style="
          margin-top: 30px;
          margin-bottom: 30px;
          font-size: 16pt;
          font-weight: 800;
        "
      >
        {{ key }}
      </h2>
      <template v-if="index == 0">
        <div v-for="(value, i) in item" :key="i">
          <Stext size="9" ele="p" top="5" indent="true">{{
            value.title
          }}</Stext>
          <div v-if="i == 0">
            <Stext
              v-for="desc in value.content"
              :key="desc"
              size="9"
              ele="p"
              top="5"
              indent="true"
              >{{ desc }}</Stext
            >
          </div>
          <div v-if="i == 1">
            <div v-for="(desc, k) in value.content" :key="k">
              <Stext
                v-if="k == 0"
                align="center"
                size="4"
                ele="p"
                top="5"
                indent="true"
                >{{ desc }}</Stext
              >
              <div v-if="k == 1">
                <STable :data="getTable(desc.流入项目, desc.流出项目)"></STable>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="index == 1">
        <div v-for="(value, i) in item" :key="i">
          <Stext size="9" ele="p" top="5" indent="true">{{
            value.title
          }}</Stext>
          <div v-if="i == 2">
            <Stext size="9" ele="p" top="5" indent="true">{{
              value.content[0]
            }}</Stext>
            <STable1 class="mt-3" :data="getTable2(value.content[i])"></STable1>
          </div>
          <div v-if="i !== 2">
            <Stext
              v-for="desc in value.content"
              :key="desc"
              size="9"
              ele="p"
              top="5"
              indent="true"
              >{{ desc }}</Stext
            >
          </div>
        </div>
      </template>
      <template v-if="index == 2">
        <div v-for="(value, i) in item" :key="i">
          <Stext size="9" ele="p" top="5" indent="true">{{
            value.title
          }}</Stext>
          <div v-if="i == 0">
            <Stext
              size="9"
              v-for="val in value.content.desc.desc"
              :key="val"
              ele="p"
              top="5"
              indent="true"
              >{{ val }}</Stext
            >

            <Stext
              align="center"
              size="4"
              ele="p"
              top="5"
              bottom="10"
              indent="true"
              >{{ value.content.title }}</Stext
            >
            <STable2 :data="value.content.table"></STable2>
          </div>
          <div v-if="i !== 0">
            <Stext
              v-for="desc in value.content"
              :key="desc"
              size="9"
              ele="p"
              top="5"
              indent="true"
              >{{ desc }}</Stext
            >
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Stext from "./text.vue";
import STable from "./sixTable1.vue";
import STable1 from "./fiveTable.vue";
import STable2 from "./rzTable.vue";
export default {
  components: {
    Stext,
    STable,
    STable1,
    STable2,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      tableData: {},
    };
  },
  created() {},
  updated() {},
  mounted() {},
  methods: {
    getTable(table, table1) {
      let header = [
        { val: "流入项目" },
        { val: "数值(万元)" },
        { val: "占流入比" },
        { val: "流出项目" },
        { val: "数值(万元)" },
        { val: "占流入比" },
      ];
      let body = [];
      table.map((item, i) => {
        item.name1 = table1[i].name;
        item.value1 = table1[i].value;
        item.rate1 = table1[i].rate;
        body.push(item);
      });
      console.log(body);

      return {
        header,
        body,
      };
    },
    getTable1(table) {
      let header = [
        { val: "流出项目" },
        { val: "数值(万元)" },
        { val: "占流入比" },
      ];
      let body = [];
      table.map((item, i) => {
        body.push(item);
      });

      return {
        header,
        body,
      };
    },
    getTable2(table) {
      let header = [];
      let arr = [];
      let body = [];
      let add = [];
      table.map((item, i) => {
        if (i == 0) {
          arr.push(item);
        } else {
          add.push(item);
        }
      });

      let aa = Object.keys(arr[0]);
      let handers = Object.values(arr[0])[0];
      header = aa.concat(handers);

      add.forEach((item, index) => {
        Object.keys(item).forEach(function (key) {
          let arrays = key + "," + item[key];
          let head = arrays.split(",");

          body.push(head);
        });
      });

      return {
        header,
        body,
      };
    },
  },
  computed: {},
};
</script>

<style lang="less" scoped></style>
