<template>
  <div class="content">
    <Stext size="6" ele="p" align="center" weigth="800">{{ downWord.company_name }}</Stext>
    <Stext size="6" ele="p" align="center" weigth="800" top="40">{{ downWord.report_yeartime }}</Stext>
  </div>
</template>

<script>
import Stext from "./text.vue";
export default {
  components: {
    Stext,
  },
  props: {
    downWord: Object,
  }
}
</script>


<style lang="less" scoped>
</style>