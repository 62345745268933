<template>
  <div class="content mt-3" style="color: #333">
    <s-text size="2" weight="700" ele="h1" top="50" bottom="20">附录</s-text>
    <s-text size="7" ele="p" top="5" indent="true"
      >本报告采用先总后分的方式，先对该企业财报分析过程中反映出来的风险水平进行评估评价，形成结论；然后再逐项进行分析。以下为重点概念的解释：
    </s-text>
    <h2
      style="
        font-size: 14pt;
        font-weight: 800;
        margin-top: 30px;
        margin-bottom: 30px;
        color: #333;
      "
    >
      1、报表质量
    </h2>
    <s-text size="7" ele="p" top="5" indent="true">
      根据专家规则及模型评判出报表质量的可信程度。财务报表质量程度区间分为：基本可信，少量存疑和明显异常。
    </s-text>

    <div
      style="
        display: flex;
        align-items: center;
        font-size: 12pt;
        line-height: 20px;
        margin-top: 10px;
      "
    >
      <p>
        <b>(1)基本可信：</b
        >在财报质量分析中，没有发现报表中存在若干或大量或重大的异常情况，说明企业提供的报表为基本可信状态。
      </p>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        font-size: 12pt;
        line-height: 20px;
        margin-top: 10px;
      "
    >
      <p>
        <b>(2)少量存疑：</b
        >在财报质量分析分析中，发现报表中存在若干异常情况，建议企业进一步提供补充信息来解释相关的异常情况，从而进一步提高报表的真实可信程度。
      </p>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        font-size: 12pt;
        line-height: 20px;
        margin-top: 10px;
      "
    >
      <p>
        <b>(3)明显异常：</b
        >在财报质量分析中，发现报表中存在大量或重大的异常情况，需要企业进一步提供补充信息来解释相关异常情况，从而进一步判断报表的真实性。
      </p>
    </div>
    <h2
      style="
        font-size: 14pt;
        font-weight: 800;
        margin-top: 30px;
        margin-bottom: 30px;
        color: #333;
      "
    >
      2、财务能力
    </h2>
    <s-text size="7" ele="p" top="5" indent="true">
      财务能力是从企业五大财务能力出发，对其相关的能力进行评价分析。本报告将目标企业的财务能力按从低到高排序，共分为五档，即：能力低、能力较低、能力中、能力较高和能力高。
    </s-text>
    <h2
      style="
        font-size: 14pt;
        font-weight: 800;
        margin-top: 30px;
        margin-bottom: 30px;
        color: #333;
      "
    >
      3、财务风险
    </h2>
    <s-text size="7" ele="p" top="5" indent="true">
      财务风险是从企业财务状况整体能力出发，对三大报表扫描分析后形成风险结论，供行内相关人员参考。本报告将目标企业的财务能力分为三档，即：风险高、风险中和风险低。
    </s-text>

    <div
      style="
        display: flex;
        align-items: center;
        font-size: 12pt;
        line-height: 20px;
        margin-top: 10px;
      "
    >
      <p>
        <b>(1)风险高：</b
        >评估结果为高，表明对于报表中反映的风险点数量及程度要“重点关注”
      </p>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        font-size: 12pt;
        line-height: 20px;
        margin-top: 10px;
      "
    >
      <p>
        <b>(2)风险中：</b
        >评估结果为中，表明对于报表中反映的风险点数量及程度“需要关注”
      </p>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        font-size: 12pt;
        line-height: 20px;
        margin-top: 10px;
      "
    >
      <p>
        <b>(3)风险低：</b
        >评估结果为低，表明对于报表中反映的风险点数量及程度“相对可控”
      </p>
    </div>
  </div>
</template>
<script>
import SText from "./text.vue";
import SPaging from "./Paging/index.vue";
export default {
  components: {
    SText,
    SPaging,
  },
  data() {
    return {
      textList1: [
        {
          name: "√ 优秀或良好：",
          text: "说明企业财务状况优秀或良好，智能报表分析结果对企业授信评级无负面影响，可以适当放宽授信",
        },
        {
          name: "√ 一般：",
          text: "说明企业财务状况一般，智能报表分析结果对企业授信评级无负面影响。",
        },
        {
          name: "√ 较差：",
          text: "说明企业财务状况较差，智能报表分析结果对企业授信评级存在负面影响。",
        },
        {
          name: "√ 很差：",
          text: "说明企业财务状况很差，智能报表分析结果对企业授信评级存在负面影响，总体上不建议进行授信。",
        },
      ],
      textList2: [
        {
          name: "√ 基本可信：",
          text: "在财报粉饰粉饰分析中，没有发现报表中存在若干或大量或重大的异常情况，说明企业提供的报表为基本可信状态。 ",
        },
        {
          name: "√ 少量存疑：",
          text: "在财报粉饰粉饰分析中，发现报表中存在若干异常情况（没到非常严重程度），建议要求企业进一步提供补充信息来解释相关的异常情况，从而进一步提高报表的真实可信程度。",
        },
        {
          name: "√ 明显异常：",
          text: "在财报粉饰粉饰分析中，发现报表中存在大量或重大的异常情况，需要要求企业进一步提供补充信息来解释相关异常情况，从而进一步判断报表的真实性。",
        },
      ],
    };
  },
  computed: {},
};
</script>
