<template>
  <table
    border="1"
    style="
      width: 100%;
      background-color: transparent;
      border-collapse: collapse;
    "
  >
    <thead>
      <tr v-for="tr in data.header" :key="tr">
        <th
          v-for="th in tr"
          :key="th"
          :colspan="th.colspan || 1"
          :rowspan="th.rowspan || 1"
          :style="
            `padding: 5px;border:1px solid ${borderColor}; font-weight:bold; background: #c7daf1;vertical-align: middle;text-align: center;` +
            th.style
          "
        >
          <s-text
            ele="p"
            size="9"
            weigth="700"
            :style="'text-align: left;' + th.style"
            >{{ th.val }}</s-text
          >
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(tr, i) in data.body" :key="i">
        <td
          v-for="th in tr"
          :key="th"
          :colspan="th.colspan || 1"
          :rowspan="th.rowspan || 1"
          :style="
            th.style
              ? th.style + `padding: 5px;border:1px solid ${borderColor}`
              : `vertical-align: middle;;padding: 5px;border:1px solid ${borderColor}`
          "
          :width="th.width"
        >
          <template v-if="th.noLine">
            <span :style="th.style" style="font-size: 10pt">
              {{ " " }}
              <br />
              {{ " " }}
            </span>
          </template>
          <template v-else>
            <s-text
              ele="p"
              size="9"
              :style="
                th.style +
                `;${
                  get_status(th.status).color
                    ? 'color:' + get_status(th.status).color
                    : ''
                };${th.val == '--' ? 'text-align: right;' : ''}`
              "
            >
              <img
                v-if="get_status(th.status).img"
                :src="get_status(th.status).img"
              />
              <img
                v-if="get_status(th.status).img"
                :src="get_status(th.status).img"
              />
              <img
                v-if="
                  showLine &&
                  (th.status == 3 ||
                    th.status == 7 ||
                    th.status == '+violent' ||
                    th.status == '-violent') &&
                  get_status(th.status).img
                "
                :src="get_status(th.status).img"
              />

              {{ th.val }}
            </s-text>
          </template>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import icon03 from "../../img/03.png";
import icon04 from "../../img/04.png";

import icon06 from "../../img/06.png";
import icon07 from "../../img/07.png";
import SText from "./text";

export default {
  components: {
    SText,
  },
  props: {
    data: Object,
    borderColor: {
      type: String,
      default: "#000",
    },
    showLine: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      status_box_mapping: ["+violent", "+big", "normal", "-big", "-violent"],
      status_box: [
        {
          color: "",
          img: icon03,
          text: "70%",
        },
        {
          color: "",
          img: icon04,
          text: "70%",
        },
        {
          color: "",
          text: "20%",
        },
        {
          color: "",
          img: icon06,
          text: "30%",
        },
        {
          color: "",
          img: icon07,
          text: "70%",
        },
      ],
      style_data: [
        { color: "#10BA34", name: "优秀值", val: 85.96 },
        { color: "#333333", name: "平均值", val: 4.8 },
        { color: "#F72222", name: "较差值", val: 0.54 },
        { color: "#333333", img: icon03, name: "波动剧烈", val: 62.99 },
        {
          color: "#333333",
          img: icon04,
          name: "波动较大",
          val: "25.90",
        },
        { color: "#333333", name: "波动平稳", val: 12.99 },
        {
          color: "#333333",
          img: icon06,
          name: "波动较大",
          val: -22.99,
        },
        { color: "#333333", img: icon07, name: "波动剧烈" },
      ],
    };
  },
  methods: {
    get_status(status) {
      if (typeof status == "string") {
        const index = this.status_box_mapping.indexOf(status);
        return this.status_box[index];
      } else if (typeof status == "number") {
        return this.style_data[status];
      } else {
        return {};
      }
    },
  },
};
</script>
