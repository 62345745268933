<template>
  <div class="content">
    <Stext size="6" ele="h1" align="center" weight="400" top="100" bottom="50">{{ data.title }}</Stext>
    <Stext class="mt-3" :key="item" size="10" ele="p" top="5">{{ data.summary }}</Stext>

    <Stext size="2" ele="p" weight="800" top="20">{{ data.details[0].title }}</Stext>
    <div class="mt-3 mb-3" style="display:flex" id="Whitewash">
      <div style="width:50%;">
        <Stext size="7" ele="p" weight="800">粉饰识别结果</Stext>
        <div ref="pie" style="width:100%;height:350px;"></div>
      </div>
      <div style="width:50%;">
        <Stext size="7" ele="p" weight="800">财务能力分析</Stext>
        <div ref="ele" style="width:100%;height:350px;"></div>
      </div>
    </div>
    <Stext class="mt-3" v-for="item in data.details[0].details" :key="item" size="10" ele="p" top="5" indent="true">{{
      item.content }}</Stext>

    <Stext size="2" ele="p" weight="800" top="20">{{ data.details[1].title }}</Stext>
    <div class=" mt-3" style="display:flex" id="risk">
      <div style="width:50%;">
        <div style="display:flex">
          <Stext size="7" ele="p" weight="800">风险评估结论</Stext>
          <el-tag class="ml-2" effect="dark" v-show="data.details[1].charts[0].content[0].risk == '风险低'" color="#3EBB9F" :style="data.details[1].charts[0].content[0].risk == '风险低'?{border:'1px solid #3EBB9F'}:{border:'1px solid #3EBB9F'}">风险低</el-tag>
          <el-tag class="ml-2" effect="dark" v-show="data.details[1].charts[0].content[0].risk == '风险中'" color="#F88E36" :style="data.details[1].charts[0].content[0].risk == '风险中'?{border:'1px solid #F88E36'}:{border:'1px solid #F88E36'}">风险中</el-tag>
          <el-tag class="ml-2" effect="dark" v-show="data.details[1].charts[0].content[0].risk == '风险高'" color="#EE5043" :style="data.details[1].charts[0].content[0].risk == '风险高'?{border:'1px solid #EE5043'}:{border:'1px solid #EE5043'}">风险高</el-tag>
        </div>
        <div class="text-center" ref="bar" style="width:100%;height:350px">
          <img v-show="data.details[1].charts[0].content[0].grade == 'A'" src="@/assets/img/detail/A.png" alt="" />
          <img v-show="data.details[1].charts[0].content[0].grade == 'A-'" src="@/assets/img/detail/A-.png" alt="" />
          <img v-show="data.details[1].charts[0].content[0].grade == 'A+'" src="@/assets/img/detail/A+.png" alt="" />
          <img v-show="data.details[1].charts[0].content[0].grade == 'B'" src="@/assets/img/detail/B.png" alt="" />
          <img v-show="data.details[1].charts[0].content[0].grade == 'B-'" src="@/assets/img/detail/B-.png" alt="" />
          <img v-show="data.details[1].charts[0].content[0].grade == 'B+'" src="@/assets/img/detail/B+.png" alt="" />
          <img v-show="data.details[1].charts[0].content[0].grade == 'C'" src="@/assets/img/detail/C.png" alt="" />
          <img v-show="data.details[1].charts[0].content[0].grade == 'C-'" src="@/assets/img/detail/C-.png" alt="" />
          <img v-show="data.details[1].charts[0].content[0].grade == 'C+'" src="@/assets/img/detail/C+.png" alt="" />
        </div>
      </div>
      <div style="width:50%;">
        <Stext size="7" ele="p" weight="800">风险提示汇总</Stext>
        <Stext class="mt-3" size="10" ele="p" top="5">
          {{ data.details[1].charts[1].content[0].details }}</Stext>
        <div ref="elt" style="width:100%;height:300px"></div>
      </div>
    </div>
    <Stext class="mt-3" v-for="item in data.details[1].detail" :key="item" size="10" ele="p" top="5" indent="true">
      {{ item.content }}</Stext>

  </div>
</template>

<script>
import Stext from "./text.vue";
import html2canvas from "html2canvas"; //文本转图片
export default {
  components: {
    Stext,
  },
  props: {
    data: Object,
  },
  watch: {
    data() {
      setTimeout(() => {
        this.init();
        this.inits();
        this.getPie();
      }, 500)
    },
  },
  methods: {
    init() {
      let data = this.data.details[0].charts[0].content[0]
      var option = {
        title: {
          show: true,
          subtext: data.horsepower_watch,
          subtextStyle: {
            color: data.horsepower_color,
            fontSize: 20
          },
          top: '47%',
          left: "center"
        },
        series: [
          {
            type: 'gauge',
            min: 0,
            max: 60,
            splitNumber: 6,
            center: ['50%', '55%'],
            radius: '65%',
            z: 999,
            axisLine: {
              show: true,
              lineStyle: {
                width: 1,
                color: [
                  [0.33, '#3A85FD'],
                  [0.67, '#FAD33F'],
                  [1, '#F88E36']
                ]
              }
            },
            splitLine: {
              distance: -18,
              length: 18,
              lineStyle: {
                color: 'auto'
              }
            },
            axisTick: {
              distance: -12,
              length: 10,
              splitNumber: 10,
              lineStyle: {
                color: 'auto'
              }
            },
            axisLabel: {
              color: "#333",
              fontSize: 16,
              distance: -30,
              // fontWeight: 550,
              rotate: 'tangential',
              formatter: function (value) {
                if (value == 10) {
                  return "基本可信";
                } else if (value === 30) {
                  return "少量存疑";
                } else if (value === 50) {
                  return "明显异常";
                }
                return "";
              },
            },
            pointer: {
              offsetCenter: [0, '-65%'],
              icon: 'path://M512 512m-448 0a448 448 0 1 0 896 0 448 448 0 1 0-896 0Z',
              width: 25,
              length: 25,
              itemStyle: {
                color: 'auto'
              }
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: data.horsepower_value,
              }
            ]
          },
          {
            type: 'gauge',
            startAngle: 0,
            endAngle: 360,
            center: ['50%', '55%'],
            radius: '55%',
            min: 0,
            max: 360,
            axisLine: {
              lineStyle: {
                width: 45
              }
            },
            pointer: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
          }
        ]
      };
      this.$echarts.init(this.$refs.pie).setOption(option); //可以解决echarts组件只加载一次问题
    },
    inits() {
      let data = this.data.details[0].charts[1].content
      let dataList = []
      data.map((item) => {
        item.name = item.name + "\n(" + item.compare + ")"
        dataList.push(item.value)
        item.max = 5
      })
      var option = {
        color: "#3A86FD",
        radar: {
          name: {
            color: "#333",
            fontSize: "16px",
          },
          radius: '40%',
          axisName: {
            fontSize: 12,
            color: '#333'
          },
          indicator: data
        },
        series: [
          {
            type: "radar",
            data: [
              {
                value: dataList
              },
            ],
          },
        ],
      };
      this.$echarts.init(this.$refs.ele).setOption(option);
      if (this.$route.query.status == 2) {
        setTimeout(() => {
          this.toImgWhitewash()
        }, 3000)
      }
    },
    getPie() {
      let dataName = this.data.details[1].charts[1].content[0].charts[0]
      let dataValue = this.data.details[1].charts[1].content[0].charts[1]
      let list = []
      var total = 0
      for (let key in dataName) {
        let obj = {}
        obj.name = dataName[key]
        obj.value = dataValue[key]
        list.push(obj)
      }
      for (var i = 0; i < dataValue.length; i++) {
        total += dataValue[i]
      }
      var option = {
        tooltip: {
          trigger: "item",
        },
        // color: ["#3E93FF","#F7882B","#FAD33F","#3EBB9F","#8886F3","#91CA74","#9DCEFF","#FFE4A4","#ED6666",],
        title: [
          // {
          //   show: true,
          //   text: "个",
          //   top: "48%",
          //   left: '53%',
          //   textStyle: {
          //     color: "#333",
          //     fontSize: 18,
          //   },
          // },
          {
            show: true,
            text: total,//总计数
            top: "45%",
            left: "center",
            textStyle: {
              color: "#3E93FF",
              fontSize: 34,
            },
          },
        ],
        series: [
          {
            type: "pie",
            radius: ["25%", "40%"],
            center: ['50%', '50%'],
            avoidLabelOverlap: false,
            label: {
              formatter: function (param) {
                return param.name + ':\n' + param.percent + '% (' + param.value + ')'
              },
              lineHeight: 20,
              fontSize: 16,
            },

            labelLine: {
              length: 20
            },
            data: list
          },
        ],
      };
      this.$echarts.init(this.$refs.elt).setOption(option);
      if (this.$route.query.status == 2) {
        setTimeout(() => {
          this.toImgRisk()
        }, 3000)
      }
    },
    toImgWhitewash() {
      if (this.$route.query.status == 0) return false;
      const Whitewash = document.querySelector("#Whitewash");
      html2canvas(Whitewash).then(function (canvas) {
        let pageData = canvas.toDataURL("image/jpeg");
        let img = new Image();
        img.src = pageData;
        img.width = "550";
        img.height = "250";
        Whitewash.innerHTML = "";
        Whitewash.appendChild(img);
      });
    },
    toImgRisk() {
      if (this.$route.query.status == 0) return false;
      const Whitewash = document.querySelector("#risk");
      html2canvas(Whitewash).then(function (canvas) {
        let pageData = canvas.toDataURL("image/jpeg");
        let img = new Image();
        img.src = pageData;
        img.width = "550";
        img.height = "250";
        Whitewash.innerHTML = "";
        Whitewash.appendChild(img);
      });
    },
    // toImg1() {
    //   const base64 = this.$refs.pie.querySelector("canvas").toDataURL();
    //   this.$refs.pie.innerHTML = `<img width="200" height="200" src="${base64}" / >`;
    // },
    // toImg2() {
    //   const base64 = this.$refs.ele.querySelector("canvas").toDataURL();
    //   this.$refs.ele.innerHTML = `<img width="200" height="200" src="${base64}" / >`;
    // },
    // toImg3() {
    //   const base64 = this.$refs.elt.querySelector("canvas").toDataURL();
    //   this.$refs.elt.innerHTML = `<img width="200" height="200" src="${base64}" / >`;
    // },
  },

}
</script>

<style lang="less" scoped>
.content {
  p {
    line-height: 1.7;
  }
}
</style>