var render = function render(){var _vm=this,_c=_vm._self._c;return _c('table',{staticStyle:{"width":"100%","font-size":"14pt","color":"#333","background-color":"transparent","border-collapse":"collapse"},attrs:{"border":"1"}},[_c('thead',[_c('tr',_vm._l((_vm.data[0]),function(tr,i){return _c('th',{key:i,staticClass:"th",staticStyle:{"background":"#c7daf1"}},[_vm._v(" "+_vm._s(tr)+" ")])}),0)]),_vm._l((_vm.data),function(tr,i){return _c('tbody',{key:i},[(i !== 0)?_c('tr',_vm._l((tr),function(td,k){return _c('td',{key:k,style:(k == 0
            ? {
                padding: '5px',
                border: '1px solid #333',
                'text-align': 'left',
              }
            : {
                padding: '5px',
                border: '1px solid #333',
                'text-align': 'right',
              })},[_vm._v(" "+_vm._s(td)+" ")])}),0):_vm._e()])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }