<template>
  <table
    border
    style="
      width: 100%;
      background-color: transparent;
      border-collapse: collapse;
      color: #333;
    "
  >
    <thead>
      <tr v-for="tr in data.header" :key="tr">
        <th
          v-for="th in tr"
          :key="th"
          :style="`font-size:14pt; padding: 10px;border:1px solid ${borderColor}; font-weight:bold; background: #c7daf1;`"
        >
          {{ th.val }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(tr, i, key) in data.body" :key="key">
        <td
          v-if="tr.rowspan"
          style="
            padding: 5px;
            border: 1px solid #333;
            font-size: 14pt;
            text-align: center;
            vertical-align: middle;
          "
          :rowspan="tr.rowspan"
        >
          {{ tr.name }}
        </td>
        <td style="padding: 5px; border: 1px solid #333; font-size: 14pt">
          {{ tr.key }}
        </td>
        <td
          style="
            text-align: right;
            padding: 5px;
            border: 1px solid #333;
            font-size: 14pt;
          "
        >
          {{ tr.cur_val }}
        </td>
        <td
          style="
            text-align: right;
            padding: 5px;
            border: 1px solid #333;
            font-size: 14pt;
          "
        >
          {{ tr.excel_val }}
        </td>
        <td
          style="
            text-align: right;
            padding: 5px;
            border: 1px solid #333;
            font-size: 14pt;
          "
        >
          {{ tr.avg_val }}
        </td>
        <td
          style="
            text-align: right;
            padding: 5px;
            border: 1px solid #333;
            font-size: 14pt;
          "
        >
          {{ tr.poor_val }}
        </td>
        <td
          style="
            text-align: right;
            padding: 5px;
            border: 1px solid #333;
            font-size: 14pt;
          "
        >
          {{ tr.differ + "%" }}
        </td>
      </tr>
      <tr v-if="data.body.length == 0">
        <td
          :colspan="7"
          style="
            text-align: center;
            padding: 5px;
            border: 1px solid #333;
            font-size: 14pt;
          "
        >
          暂无数据
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import SText from "./text.vue";
export default {
  components: {
    SText,
  },
  props: {
    data: Object,
    borderColor: {
      type: String,
      default: "#000",
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped>
</style>
