<template>
  <div class="content">
    <s-text
      size="2"
      ele="h1"
      align="center"
      weight="800"
      top="60"
      bottom="50"
      >{{ data.title1 }}</s-text
    >
    <!-- 4.1 -->
    <div>
      <h2 style="font-size: 12pt; font-weight: 800; color: #000">
        {{ data.content[0].title2 }}
      </h2>
      <!-- 指标变动 -->
      <!-- <s-text ele="h3" weigth="900" size="10" top="20" bottom="20">
        本表仅展示六大能力中当期波动最大的1个指标，详细指标请查看附表-财务指标变动表。
      </s-text>
      <s-table
        :showLine="true"
        :data="getTwoTableData(data.content[0].content[0].table)"
      ></s-table>
      <div
        style="
          margin-top: 20px;
          font-size: 16px;
          text-align: center;
          font-weight: 800;
        "
      >
        {{ data.content[0].content[0].char.title }}
      </div> -->
      <!-- 6大能力 -->
      <!-- <div ref="eleOne" style="width: 100%; height: 540px"></div> -->
      <!--小图例  -->
      <!-- <div id="imgList" ref="imgList">
        <div class="groundcolor" style="background: #f72222"></div>
        <div style="font-size: 14px; color: rgb(90, 84, 84)">当前值≤较差值</div>
        <div class="groundcolor" style="background: #fec400"></div>
        <div style="font-size: 14px; color: rgb(90, 84, 84)">
          较差值＜当前值＜优秀值
        </div>
        <div class="groundcolor" style="background: #10ba34"></div>
        <div style="font-size: 14px; color: rgb(90, 84, 84)">当前值≥优秀值</div>

      </div> -->

      <s-text
        size="10"
        ele="p"
        top="20"
        indent="true"
        v-for="(item, i) in data.content[0].content[0].desc"
        :key="i"
      >
        {{ item }}
      </s-text>
    </div>
    <!-- 4.2 -->
    <div class="mt-4">
      <h2 style="font-size: 12pt; font-weight: 800; color: #000">
        {{ data.content[1].title2 }}
      </h2>
      <div v-for="(item, i) in data.content[1].content" :key="i">
        <s-text
          size="10"
          ele="p"
          top="20"
          indent="true"
          v-for="(item1, i) in item"
          :key="i"
        >
          {{ item1 }}
        </s-text>
      </div>
    </div>
    <!-- 4.3 -->
    <!-- <div>
      <h2 style="font-size: 12pt; font-weight: 800; color: #000">
        {{ data.content[2].title2 }}
      </h2>
      <div v-for="(item, i) in data.content[2].content" :key="i">
        <s-text
          ele="h3"
          v-for="(item1, i) in item.before_char"
          :key="i"
          weigth="500"
          size="10"
          top="10"
          bottom="10"
          >{{ item1 }}</s-text
        >
        <div id="imgbox" ref="imgbox">
          <div
            id="three1"
            ref="three1"
            style="height: 50%; width: 50%; border: 1px solid #ccc"
          ></div>
          <div
            id="three2"
            ref="three2"
            style="height: 50%; width: 50%; border: 1px solid #ccc"
          ></div>
          <div
            id="three3"
            ref="three3"
            style="height: 50%; width: 50%; border: 1px solid #ccc"
          ></div>
          <div
            id="three4"
            ref="three4"
            style="height: 50%; width: 50%; border: 1px solid #ccc"
          ></div>
        </div>
        <s-text
          size="10"
          ele="p"
          top="20"
          indent="true"
          v-for="(item1, i) in item.desc"
          :key="i"
          >{{ item1 }}</s-text
        >
      </div>
    </div> -->
    <!-- 4.4 -->
    <div>
      <h2 style="font-size: 12pt; font-weight: 800; color: #000">
        {{ data.content[2].title2 }}
      </h2>
      <div ref="ele" style="height: 350px"></div>
      <s-text ele="h2" top="20" indent="true" weight="800">
        {{ data.content[2].content[0].note }}
      </s-text>
      <s-text
        size="10"
        ele="p"
        top="20"
        indent="true"
        v-for="(item, i) in data.content[2].content[0].desc"
        :key="i"
      >
        {{ item }}
      </s-text>
    </div>
  </div>
</template>

<script>
import SText from "./text";
import STable from "./table";
import html2canvas from "html2canvas"; //文本转图片
export default {
  components: {
    SText,
    STable,
  },
  props: {
    data: Object,
  },
  data() {
    return {};
  },
  computed: {},
  updated() {
    // setTimeout(this.toImgbox.bind(this), 0);
  },
  mounted() {
    // this.sysTemRuleChart();
    // this.sysTemRuleChartThree1();
    // this.sysTemRuleChartThree2();
    // this.sysTemRuleChartThree3();
    // this.sysTemRuleChartThree4();
    // setTimeout(() => {
    //   this.init();
    // }, 1000)
  },

  methods: {
    // 4.1
    //   getTwoTableData(table) {
    //     const header = this.getHeader(table);
    //     const body = this.getBody(table);
    //     // const body = [];
    //     return {
    //       header,
    //       body,
    //     };
    //   },
    //   getHeader(table) {
    //     let header = [
    //       { rowspan: 2, val: "指标大类", style: "text-align: left;" },
    //       { val: "指标名称", rowspan: 2, style: "text-align: left;" },
    //     ];
    //     let header2 = [];
    //     table.some((item_power) => {
    //       Object.values(item_power).some((item) => {
    //         Object.values(item).some((item) => {
    //           Object.values(item).some((item) => {
    //             item.map((item) => {
    //               for (const key in item) {
    //                 const nameArr = this.getRowspan(item[key]);
    //                 header2 = header2.concat(nameArr);
    //                 header.push({
    //                   val: key,
    //                   colspan: nameArr.length,
    //                   style: "text-align: center;",
    //                 });
    //               }
    //             });
    //           });
    //           return true;
    //         });
    //         return true;
    //       });
    //       return true;
    //     });
    //     return [header, header2];
    //   },
    //   getRowspan(item) {
    //     const arr = ["指标值", "变动率"];
    //     const returnArr = [];
    //     Object.keys(item).some((key) => {
    //       if (arr.includes(key))
    //         returnArr.push({ val: key, style: "text-align: center;" });
    //     });

    //     return returnArr;
    //   },
    //   getBody(table) {
    //     const rule_analysis_data = [];

    //     table.some((item_power) => {
    //       Object.values(item_power).some((item) => {
    //         const powerArr = item;
    //         item.some((item, i) => {
    //           const row = [];
    //           const name = Object.keys(item_power)[0];

    //           const powerItem = {
    //             val: name,
    //             rowspan: powerArr.length,
    //           };

    //           if (0 == i) row.push(powerItem);
    //           row.push({ val: Object.keys(item)[0] });

    //           Object.values(item).map((item) => {
    //             item.map((item) => {
    //               const oneItem = Object.values(item)[0];
    //               const 指标值 = {
    //                 val: oneItem.指标值,
    //                 status: oneItem.flag < 0 ? 1 : oneItem.flag,
    //                 style: "text-align: right;",
    //               };
    //               const 变动率 = {
    //                 val: oneItem.变动率,
    //                 status:
    //                   oneItem.increase_flag < 0 ? 1 : 3 + oneItem.increase_flag,
    //                 style: "text-align: right;",
    //               };
    //               row.push(指标值);
    //               if (oneItem.变动率) row.push(变动率);
    //             });
    //           });
    //           rule_analysis_data.push(row);
    //         });
    //       });
    //     });
    //     return rule_analysis_data;
    //   },
    //   // sysTemRuleChart() {
    //   //   // 获取dom
    //   //   let dataList = this.data.content[0].content[0].char;
    //   //   let colorList = [
    //   //     "#10BA34", //绿色
    //   //     "#FEC400", //黄橙色
    //   //     "#F72222", //红色
    //   //     // "#DDDBD2", //灰色
    //   //   ];
    //   //   let optionOne = {
    //   //     // title: {
    //   //     // subtext:"风险程度\n"+"\n" +dataList.overall_risk,
    //   //     // x: "center",
    //   //     // y: "43%",
    //   //     // textStyle: {
    //   //     //   fontSize: 25,
    //   //     //   fontWeight: "normal",
    //   //     //   color: ["#333"],
    //   //     // },
    //   //     // subtextStyle: {
    //   //     //   color: "#666",
    //   //     //   fontSize: 16,
    //   //     // },
    //   //     // },
    //   //     // 图例位置
    //   //     // grid: {
    //   //     //   bottom: 50,
    //   //     //   left: 0,
    //   //     //   right: "10%",
    //   //     // },
    //   //     // 小标
    //   //     // legend: {
    //   //     //   selectedMode: false,
    //   //     //   data: [
    //   //     //     "当前值≤较差值",
    //   //     //     "较差值<当前值<优秀值",
    //   //     //     "当前值≥优秀值",
    //   //     //     "无法计算",
    //   //     //   ],
    //   //     //   show: true,
    //   //     //   top: "0",
    //   //     //   left: "center",
    //   //     //   textStyle: {
    //   //     //     color: "#333",
    //   //     //     fontSize: 14,
    //   //     //   },
    //   //     // },
    //   //     graphic: [
    //   //       {
    //   //         type: "group",
    //   //         left: "center",
    //   //         top: "middle",
    //   //         children: [
    //   //           {
    //   //             type: "text",
    //   //             z: 100,
    //   //             left: "center",
    //   //             top: "0",
    //   //             style: {
    //   //               fill: "#ccc",
    //   //               text: ["财务风险"],
    //   //               font: "16px Microsoft YaHei",
    //   //             },
    //   //           },
    //   //           {
    //   //             type: "text",
    //   //             z: 100,
    //   //             left: "center",
    //   //             top: "32",
    //   //             style: {
    //   //               fill: "#000",
    //   //               text: dataList.overall_risk,
    //   //               font: "18px Microsoft YaHei",
    //   //             },
    //   //           },
    //   //         ],
    //   //       },
    //   //     ],
    //   //     series: [
    //   //       // 主要展示层的
    //   //       {
    //   //         data: dataList.detail,
    //   //         radius: ["16%", "30%"],
    //   //         center: ["50%", "50%"],
    //   //         type: "pie",
    //   //         itemStyle: {
    //   //           normal: {
    //   //             color: function (params) {
    //   //               return colorList[params.dataIndex];
    //   //             },
    //   //           },
    //   //         },
    //   //         // 饼图线条
    //   //         labelLine: {
    //   //           normal: {
    //   //             show: true,
    //   //             length: 5,
    //   //             length2: 80,
    //   //             lineStyle: {
    //   //               color: "#d3d3d3",
    //   //             },
    //   //             // align: "right",
    //   //           },
    //   //           color: "#000",
    //   //           emphasis: {
    //   //             show: true,
    //   //           },
    //   //         },
    //   //         label: {
    //   //           normal: {
    //   //             formatter: function (params) {
    //   //               // console.log(params);
    //   //               // 每两个分为一个新数组
    //   //               var arrlist = [];
    //   //               function chunk(arr, num) {
    //   //                 let j = 0,
    //   //                   o = j;
    //   //                 let newArray = [];
    //   //                 while (j < arr.length) {
    //   //                   j += num;
    //   //                   newArray.push(arr.slice(o, j));
    //   //                   o = j;
    //   //                 }
    //   //                 arrlist = newArray;
    //   //               }
    //   //               chunk(params.data.info, 2);

    //   //               var str = "";
    //   //               switch (params.data.name) {
    //   //                 // case "无法计算":
    //   //                 //   str =
    //   //                 //     "{a|} {nameStyle|" +
    //   //                 //     params.data.name +
    //   //                 //     "} {rate|" +
    //   //                 //     params.percent +
    //   //                 //     "%}\n{zhibiao|" +
    //   //                 //     params.value +
    //   //                 //     "}个指标" +
    //   //                 //     "\n{textStyle|" +
    //   //                 //     arrlist.join("\n") +
    //   //                 //     "}";
    //   //                 //   break;
    //   //                 case "较差值<当前值<优秀值":
    //   //                   str =
    //   //                     " {b|} {nameStyle|" +
    //   //                     params.data.name +
    //   //                     "} {rateStylesa|" +
    //   //                     params.percent +
    //   //                     "%}" +
    //   //                     "\n{zhibiao|" +
    //   //                     params.value +
    //   //                     "}个指标" +
    //   //                     "\n{textStyle|" +
    //   //                     arrlist.join("\n") +
    //   //                     "}";
    //   //                   break;
    //   //                 case "当前值≥优秀值":
    //   //                   str =
    //   //                     "{c|} {nameStyle|" +
    //   //                     params.data.name +
    //   //                     "} {rateStyles|" +
    //   //                     params.percent +
    //   //                     "%}\n{zhibiao|" +
    //   //                     params.value +
    //   //                     "}个指标" +
    //   //                     "\n{textStyle|" +
    //   //                     arrlist.join("\n") +
    //   //                     "}";
    //   //                   break;
    //   //                 case "当前值≤较差值":
    //   //                   str =
    //   //                     "                 {d|} {nameStyle|" +
    //   //                     params.data.name +
    //   //                     "} {rateStyle|" +
    //   //                     params.percent +
    //   //                     "%}\n{zhibiao|" +
    //   //                     params.value +
    //   //                     "}个指标" +
    //   //                     "\n{textStyle|" +
    //   //                     arrlist.join("\n") +
    //   //                     "}";
    //   //                   break;
    //   //               }
    //   //               return str;
    //   //             },
    //   //             rich: {
    //   //               // a: {
    //   //               //   width: 14,
    //   //               //   height: 14,
    //   //               //   backgroundColor: "#DDDBD2",
    //   //               //   align: "left",
    //   //               //   borderRadius: 14,
    //   //               // },
    //   //               b: {
    //   //                 width: 14,
    //   //                 height: 14,
    //   //                 backgroundColor: "#FEC400",
    //   //                 align: "left",
    //   //                 borderRadius: 14,
    //   //               },
    //   //               c: {
    //   //                 width: 14,
    //   //                 height: 14,
    //   //                 backgroundColor: "#10BA34",
    //   //                 align: "left",
    //   //                 borderRadius: 14,
    //   //               },
    //   //               d: {
    //   //                 width: 14,
    //   //                 height: 14,
    //   //                 backgroundColor: "#F72222",
    //   //                 align: "left",
    //   //                 borderRadius: 14,
    //   //               },
    //   //               nameStyle: {
    //   //                 fontSize: 15,
    //   //                 color: "#555",
    //   //                 align: "left",
    //   //               },
    //   //               // rate: {
    //   //               //   fontSize: 17,
    //   //               //   color: "#DDDBD2",
    //   //               //   align: "left",
    //   //               // },
    //   //               rateStyle: {
    //   //                 fontSize: 17,
    //   //                 color: "#F72222",
    //   //                 align: "left",
    //   //               },
    //   //               rateStyles: {
    //   //                 fontSize: 17,
    //   //                 color: "#10BA34",
    //   //                 align: "left",
    //   //               },
    //   //               rateStylesa: {
    //   //                 fontSize: 17,
    //   //                 color: "#FEC400",
    //   //                 align: "left",
    //   //               },
    //   //               textStyle: {
    //   //                 // fontSize: 12,
    //   //                 lineHeight: 18,
    //   //               },
    //   //               zhibiao: {
    //   //                 lineHeight: 20,
    //   //               },
    //   //             },
    //   //           },
    //   //         },
    //   //       },
    //   //       {
    //   //         radius: ["30%", "27%"],
    //   //         center: ["50%", "50%"],
    //   //         type: "pie",
    //   //         label: {
    //   //           normal: {
    //   //             show: false,
    //   //           },
    //   //           emphasis: {
    //   //             show: false,
    //   //           },
    //   //         },
    //   //         labelLine: {
    //   //           normal: {
    //   //             show: false,
    //   //           },
    //   //           emphasis: {
    //   //             show: false,
    //   //           },
    //   //         },
    //   //         animation: false,
    //   //         tooltip: {
    //   //           show: false,
    //   //         },
    //   //         itemStyle: {
    //   //           normal: {
    //   //             color: "rgba(250,250,250,0.5)",
    //   //           },
    //   //         },
    //   //         data: [
    //   //           {
    //   //             value: 1,
    //   //           },
    //   //         ],
    //   //       },
    //   //     ],
    //   //     // 鼠标悬浮提示框
    //   //     tooltip: {
    //   //       // trigger: 'item',
    //   //       formatter: " {b} {d}% <br/>" + " {c}个指标",
    //   //     },
    //   //   };
    //   //   this.$echarts.init(this.$refs.eleOne).setOption(optionOne);
    //   //   if (this.$route.query.status == 2) {
    //   //     setTimeout(() => {
    //   //       this.toImgbox();
    //   //       this.toImg1();
    //   //     }, 1000);
    //   //   }
    //   // },
    //   // toImg1() {
    //   //   const base64 = this.$refs.eleOne.querySelector("canvas").toDataURL();
    //   //   this.$refs.eleOne.innerHTML = `<img width="620" height="350" src="${base64}" / >`;
    //   // },
    //   // toImgbox() {
    //   //   if (this.$route.query.status == 0) return false;
    //   //   const imgList = document.querySelector("#imgList");
    //   //   html2canvas(imgList).then(function (canvas) {

    //   //     let pageData = canvas.toDataURL("image/jpeg");
    //   //     let img = new Image();
    //   //     img.src = pageData;
    //   //     img.width = "620";
    //   //     img.height = "18";
    //   //     imgList.innerHTML = "";
    //   //     imgList.appendChild(img);
    //   //   });
    //   // },
    //   // 4.3
    //   // toImgthree() {
    //   //   if (this.$route.query.status == 0) return false;
    //   //   const imgbox = document.querySelector("#imgbox");
    //   //   html2canvas(imgbox).then(function (canvas) {

    //   //     let pageData = canvas.toDataURL("image/jpeg");
    //   //     let img = new Image();
    //   //     img.src = pageData;
    //   //     img.width = "625";
    //   //     img.height = "400";
    //   //     imgbox.innerHTML = "";
    //   //     imgbox.appendChild(img);
    //   //   });
    //   // },

    //   // sysTemRuleChartThree1() {
    //   //   let myChartthree1 = this.$echarts.init(document.getElementById("three1"));
    //   //   let dataList4 = this.data.content[2].content[0].char;
    //   //   myChartthree1.setOption({
    //   //     grid: {
    //   //       bottom: 40,
    //   //       top: 60,
    //   //       right: 40,
    //   //       left: 50,
    //   //     },
    //   //     title: {
    //   //       text: dataList4.y_axis[0].label,
    //   //       x: "center",
    //   //       y: 10,
    //   //     },
    //   //     xAxis: {
    //   //       type: "category",
    //   //       data: dataList4.x_axis, //年份
    //   //     },
    //   //     yAxis: {
    //   //       type: "value",
    //   //       data: ["0", "1", "2", "3", "4"],
    //   //     },
    //   //     series: [
    //   //       {
    //   //         type: "line",
    //   //         data: dataList4.y_axis[0].data,
    //   //         lineStyle: {
    //   //           normal: {
    //   //             color: "#3AB870",
    //   //           },
    //   //         },
    //   //         itemStyle: {
    //   //           normal: {
    //   //             color: "#3AB870",
    //   //           },
    //   //         },
    //   //       },
    //   //     ],
    //   //   });
    //   //   if (this.$route.query.status == 2) {
    //   //     setTimeout(() => {
    //   //       this.toImgthree();
    //   //     }, 1000);
    //   //   }
    //   // },

    //   // sysTemRuleChartThree2() {
    //   //   let myChartthree2 = this.$echarts.init(document.getElementById("three2"));
    //   //   let dataList4 = this.data.content[2].content[0].char;
    //   //   myChartthree2.setOption({
    //   //     grid: {
    //   //       bottom: 40,
    //   //       top: 60,
    //   //       right: 40,
    //   //       left: 50,
    //   //     },
    //   //     title: {
    //   //       text: dataList4.y_axis[1].label,
    //   //       x: "center",
    //   //       y: 10,
    //   //     },
    //   //     xAxis: {
    //   //       type: "category",
    //   //       data: dataList4.x_axis, //年份
    //   //     },
    //   //     yAxis: {
    //   //       type: "value",
    //   //       data: ["0", "1", "2", "3", "4"],
    //   //     },
    //   //     series: [
    //   //       {
    //   //         type: "line",
    //   //         data: dataList4.y_axis[1].data,
    //   //         lineStyle: {
    //   //           normal: {
    //   //             color: "#F4BC32",
    //   //           },
    //   //         },
    //   //         itemStyle: {
    //   //           normal: {
    //   //             color: "#F4BC32",
    //   //           },
    //   //         },
    //   //       },
    //   //     ],
    //   //   });
    //   // },
    //   // sysTemRuleChartThree3() {
    //   //   let myChartthree3 = this.$echarts.init(document.getElementById("three3"));
    //   //   let dataList4 = this.data.content[2].content[0].char;
    //   //   myChartthree3.setOption({
    //   //     grid: {
    //   //       bottom: 40,
    //   //       top: 60,
    //   //       right: 40,
    //   //       left: 50,
    //   //     },
    //   //     title: {
    //   //       text: dataList4.y_axis[2].label,
    //   //       x: "center",
    //   //       y: 10,
    //   //     },
    //   //     xAxis: {
    //   //       type: "category",
    //   //       data: dataList4.x_axis,
    //   //     },
    //   //     yAxis: {
    //   //       type: "value",
    //   //       data: ["0", "1", "2", "3", "4"],
    //   //     },
    //   //     series: [
    //   //       {
    //   //         type: "line",
    //   //         data: dataList4.y_axis[2].data,
    //   //         lineStyle: {
    //   //           normal: {
    //   //             color: "#1989FA",
    //   //           },
    //   //         },
    //   //         itemStyle: {
    //   //           normal: {
    //   //             color: "#1989FA",
    //   //           },
    //   //         },
    //   //       },
    //   //     ],
    //   //   });
    //   // },
    //   // sysTemRuleChartThree4() {
    //   //   let myChartthree4 = this.$echarts.init(document.getElementById("three4"));
    //   //   let dataList4 = this.data.content[2].content[0].char;
    //   //   myChartthree4.setOption({
    //   //     grid: {
    //   //       bottom: 40,
    //   //       top: 60,
    //   //       right: 40,
    //   //       left: 50,
    //   //     },
    //   //     title: {
    //   //       x: "center",
    //   //       y: 10,
    //   //       text: dataList4.y_axis[3].label,
    //   //     },
    //   //     xAxis: {
    //   //       type: "category",
    //   //       data: dataList4.x_axis, //年份
    //   //     },

    //   //     yAxis: {
    //   //       type: "value",
    //   //       data: ["0", "1", "2", "3", "4"],
    //   //     },
    //   //     series: [
    //   //       {
    //   //         type: "line",
    //   //         data: dataList4.y_axis[3].data,
    //   //         lineStyle: {
    //   //           normal: {
    //   //             color: "#FF8534",
    //   //           },
    //   //         },
    //   //         itemStyle: {
    //   //           normal: {
    //   //             color: "#FF8534",
    //   //           },
    //   //         },
    //   //       },
    //   //     ],
    //   //   });
    //   // },
    //   // 4.4
    init() {
      const title = [];
      const data = [];
      if (
        this.data.content[2].content[0].chart &&
        this.data.content[2].content[0].chart.length
      ) {
        for (
          var i = this.data.content[2].content[0].chart.length - 1;
          i >= 0;
          i--
        ) {
          var row = this.data.content[2].content[0].chart[i];
          // console.log(row);
          title.push(row.name);
          data.push(100 - row.pos_val);
        }
      }
      var option = {
        // 鼠标停留展示
        // tooltip: {
        //   trigger: "axis",
        //   axisPointer: {
        //     type: "shadow",
        //   },
        // },
        legend: {
          selectedMode: false,
          type: "plain", // 普通图例
          orient: "horizontal", // 水平
          data: [
            "排名前0-10%",
            "排名前10-32.5%",
            "排名前32.5-67.5%",
            "排名前67.5-90%",
            "排名前90-100%",
          ],
          bottom: 5,
        },
        // 位置
        // grid: {
        //   left: "3%",
        //   containLabel: true,
        // },
        xAxis: {
          type: "value",
          show: true,
          min: 0,
          max: 100,
          // minInterval:10,
          // maxInterval:32.5,
          interval: 10,
          axisLine: {
            show: true,
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: [
                "#ccc",
                "#ccc",
                "#FFF",
                "#ccc",
                "#FFF",
                "#FFF",
                "#ccc",
                "#FFF",
                "#FFF",
                "#ccc",
                "#ccc",
              ],
            },
          },
          axisLabel: {
            formatter: function (value, i) {
              // console.log(value,i);
              var texts = [];
              if (i == 1) {
                texts.push("{LabelStyle1|\n\n排名前\n90 - 100%}");
              } else if (i == 2) {
                texts.push("{LabelStyle2|\n\n排名前\n67.5 - 90%}");
              } else if (i == 5) {
                texts.push("{LabelStyle3|\n\n排名前\n32.5 - 67.5%}");
              } else if (i == 8) {
                texts.push("{LabelStyle4|\n\n排名前\n10 - 32.5%}");
              } else if (i == 10) {
                texts.push("{LabelStyle1|\n\n排名前\n0 - 10%}");
              }
              return texts;
            },
            rotate: 0.1,
            // padding: 10,
            rich: {
              LabelStyle1: {
                padding: 10,
                align: "center",
                lineHeight: 15,
              },
              LabelStyle2: {
                padding: -20,
                align: "center",
                lineHeight: 15,
              },
              LabelStyle3: {
                padding: 2,
                align: "center",
                lineHeight: 15,
              },
              LabelStyle4: {
                padding: 2,
                align: "center",
                lineHeight: 15,
              },
            },
          },
        },
        yAxis: {
          type: "category",
          data: title,
          axisLine: {
            show: true,
          },
        },
        series: [
          {
            name: "排名前0-10%",
            type: "bar",
            barWidth: "50%",
            data: data,
            color: "#529EE2",
            itemStyle: {
              normal: {
                color: function (params) {
                  if (params.data <= 10) {
                    return "#F2637B";
                  }
                  if (params.data <= 32.5) {
                    return "#F88E36";
                  }
                  if (params.data > 32.5 && params.data <= 67.5) {
                    return " #FAD33F";
                  }
                  if (params.data <= 90) {
                    return "#3EBB9F";
                  }
                  if (params.data <= 100) {
                    return "#529EE2";
                  }
                },
              },
            },
          },
          {
            name: "排名前10-32.5%",
            type: "bar",
            color: "#3EBB9F",
            barWidth: "0%",
          },
          {
            name: "排名前32.5-67.5%",
            barWidth: "0%",
            type: "bar",
            color: "#FAD33F",
          },
          {
            name: "排名前67.5-90%",
            barWidth: "0%",
            type: "bar",
            color: "#F88E36",
          },
          {
            name: "排名前90-100%",
            barWidth: "0%",
            type: "bar",
            color: "#F2637B",
          },
        ],
      };
      this.$echarts.init(this.$refs.ele).setOption(option);
      if (this.$route.query.status == 2) {
        setTimeout(() => {
          this.toImg();
        }, 1500);
      }
    },
    toImg() {
      const base64 = this.$refs.ele.querySelector("canvas").toDataURL();
      this.$refs.ele.innerHTML = `<img width="600" height="250" src="${base64}" / >`;
    },
  },
  watch: {
    data() {
      // setTimeout(this.sysTemRuleChart, 200);

      // setTimeout(this.sysTemRuleChartThree1, 200);
      // setTimeout(this.sysTemRuleChartThree2, 200);
      // setTimeout(this.sysTemRuleChartThree3, 200);
      // setTimeout(this.sysTemRuleChartThree4, 200);

      setTimeout(() => {
        this.init();
      }, 500);
    },
  },
};
</script>
<style lang="less" scoped>
#imgbox {
  border: 1px solid #ccc;
  width: 100%;
  height: 550px;
  display: flex;
  flex-wrap: wrap;
}

.groundcolor {
  width: 28px;
  height: 15px;
  border-radius: 5px;
  margin: 5px;
}

#imgList {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  width: 100%;
  height: 20px;
}
</style>
