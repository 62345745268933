import service from "@/common/request.js";

/**
 * get_company_list
 * @param {company_code} string
 * @param {company_name} string
 * @param {report_type} string
 * @param {report_time} string
 * @param {year} string
 * @param {form_data} object
 */

// import data from "./filterData/data";
//
export function get_table_data(param) {
  return service.post("/report/json/credit/", param);

  // return new Promise((a, b) => {
  //   a({ data });
  //   // service
  //   //   .post("/3v3/abcd/", param)
  //   //   .then(({ data }) => {

  //   //   })
  //   //   .catch(() => {

  //   //   });

  //   //   return service.post("get_company_list", param);
  // });
}

export function rule_analysis(param) {
  return service.post("/report/json/credit-ex/", param);
}

export function fp_simpleanalysis(param) {
  return service.post("/report/json/fp/", param);
}

export function r_micro_report(param) {
  return service.post("/3v3/micro_report/", param);
}

/**
 * 
 * @param {Object} param {
    "year": "2020",
    "report_type": "00",
    "report_time": "00",
    "industry_code": "sw250101",
    "company_code": "63e992cc-abf1-11e8-86fc-1c83410ad7e3"
} 
 */
export function credit_tpl4(param) {
  return service.post("/report/json/credit-tpl4/", param);
}

/**
 * 
 * @param {Object} param {
    "year": "2020",
    "report_type": "00",
    "report_time": "00",
    "industry_code": "sw250101",
    "company_code": "63e992cc-abf1-11e8-86fc-1c83410ad7e3"
} 
 */
export function credit_tpl5(param) {
  return service.post("/report/json/credit-tpl5/", param);
}

/**
 * 
 * @param {Object} param {
    "year": "2020",
    "report_type": "00",
    "report_time": "00",
    "industry_code": "sw250101",
    "company_code": "63e992cc-abf1-11e8-86fc-1c83410ad7e3"
  } 
 */
export const credit_basic = (param) =>
  service.post(`/report/json/credit-basic/`, param)


/**
* 
* @param {Object} param {
  "year": "2020",
  "report_type": "00",
  "report_time": "00",
  "industry_code": "sw250101",
  "company_code": "63e992cc-abf1-11e8-86fc-1c83410ad7e3"
} 
*/
export const simple_report = (param) =>
  service.post(`report/json/simple-report/`, param)


// 行业比较分析
export const industry = (param) =>
  service.post(`/report/json/industry/`, param)

// 报表质量分析
export const quality = (param) =>
  service.post(`/report/json/quality/`, param)

// 财务分析分析
export const main_report = (param) =>
  service.post(`/report/json/main-report/`, param)