<template>
  <div class="content">
    <Stext size="6" align="center" weight="700" ele="h1" top="50" bottom="20">{{
      data.title
    }}</Stext>

    <div v-for="(item, index) in data.content" :key="index">
      <Stext v-if="index !== 2" size="9" ele="p" top="5" indent="true">{{
        item
      }}</Stext>
      <div v-if="index == 2">
        <div v-for="(value, val, key) in item.detail" :key="key">
          <Stext size="9" ele="p" top="15" bottom="15" indent="true">{{
            key + 1 + "、" + val
          }}</Stext>
          <STable :data="getTable(value)"></STable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Stext from "./text.vue";

import STable from "./sixTable.vue";

export default {
  components: {
    Stext,
    STable,
  },
  props: {
    data: Object,
  },
  data() {
    return {};
  },
  created() {},
  updated() {},
  methods: {
    getTable(table) {
      let header = [
        { val: "关注点" },
        { val: "风险等级" },
        { val: "财务表现" },
        { val: "风险提示" },
      ];
      let body = [];

      body = table;
      return {
        header,
        body,
      };
    },
  },
  computed: {},
};
</script>

<style lang="less" scoped>
.th {
  padding: 5px;
  border: 1px solid #333;
  font-weight: bold;
  background: #c7daf1;
  vertical-align: middle;
  text-align: center;
}
</style>
