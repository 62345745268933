<template>
  <h1
    v-if="ele == 'h1'"
    :style="style ? style + ';font-weight: 700;' : getStyle + ';font-weight: 700;'"
  >
    <slot></slot>
  </h1>
  <p v-else :style="style ? style : getStyle">
    <slot></slot>
  </p>
</template>
<script>
export default {
  props: {
    ele: {
      type: String,
      default: "h1",
    },
    style: String,
    align: {
      type: String,
      default: "left",
    },
    size: {
      type: String,
      default: 7,
    },
    color: {
      type: String,
      default: "#000",
    },
    top: {
      type: String,
      default: 0,
    },
    bottom: {
      type: String,
      default: 0,
    },
    border: {
      type: String,
    },
    weight: String,
    indent: String,
  },
  data() {
    return {
      sizes: {
        1: 36,
        2: 16,
        3: 12,
        4: 12,
        5: 17,
        6: 18,
        7: 12,
        8: 26,
        9: 14,
        10: 10.5,
      },
    };
  },
  computed: {
    getStyle() {
      const style =
        (this.weight ? `font-weight: ${this.weight};` : "") +
        (this.indent ? `text-indent:${this.sizes[this.size] * 2}pt;` : "") +
        `text-align: ${this.align};font-size: ${this.sizes[this.size]}pt;color: ${
          this.color
        };margin-top: ${this.top}px;;margin-bottom: ${this.bottom}px;${
          this.border ? `border:${this.border}pt solid #000;` : ""
        };$`;

      return style;
    },
  },
  methods: {
    // getStyle() {
    //
    // },
  },
};
</script>
