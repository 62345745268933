<template>
  <div class="content">
    <Stext size="6" align="center" weight="700" ele="h1" top="50" bottom="20">{{
      data.title
    }}</Stext>
    <div v-for="(item, index) in data.content" :key="index">
      <h2
        style="
          margin-top: 30px;
          margin-bottom: 30px;
          font-size: 16pt;
          font-weight: 800;
          color: #333;
        "
      >
        {{ item.title }}
      </h2>

      <template v-if="index == 0">
        <div v-for="(val, i) in item.content" :key="i">
          <Stext size="9" ele="p" top="5" indent="true">{{ val.title }}</Stext>
          <div v-if="i == 0">
            <Stext
              v-for="desc in val.content"
              :key="desc"
              size="9"
              ele="p"
              top="5"
              indent="true"
              >{{ desc }}</Stext
            >
          </div>
          <div v-if="i == 1">
            <div v-for="(desc, k) in val.content" :key="k">
              <Stext
                v-if="k == 4"
                size="4"
                ele="p"
                align="center"
                top="5"
                indent="true"
                >{{ desc }}</Stext
              >
              <Stext
                v-if="k != 4 && k != 5"
                size="9"
                ele="p"
                top="5"
                bottom="10"
                indent="true"
                >{{ desc }}</Stext
              >
              <div v-if="k == 5">
                <STable :data="getTable(desc)"></STable>
              </div>
            </div>
          </div>
          <div v-if="i == 2">
            <div v-for="(desc, k) in val.content" :key="k">
              <Stext
                v-if="k == 3 || k == 6"
                size="4"
                ele="p"
                top="5"
                bottom="10"
                align="center"
                indent="true"
                >{{ desc }}</Stext
              >
              <div v-if="k == 4 || k == 7">
                <STable :data="getTable(desc)"></STable>
              </div>
              <Stext
                v-if="k == 0 || k == 1 || k == 2 || k == 5"
                size="9"
                ele="p"
                top="10"
                indent="true"
                >{{ desc }}</Stext
              >
            </div>
          </div>
        </div>
      </template>

      <template v-if="index == 1">
        <div v-for="(des, i) in item.content" :key="i">
          <Stext size="9" ele="p" top="5" indent="true">{{ des.title }}</Stext>
          <div v-if="i == 0">
            <Stext
              v-for="val in des.content[0]"
              :key="val"
              size="9"
              ele="p"
              top="5"
              bottom="10"
              indent="true"
              >{{ val }}</Stext
            >
          </div>

          <div v-if="i == 1">
            <div v-for="(val, k) in des.content" :key="k">
              <div v-if="k == 0">
                <Stext
                  v-for="value in val"
                  :key="value"
                  size="9"
                  ele="p"
                  top="5"
                  bottom="10"
                  indent="true"
                  >{{ value }}</Stext
                >
              </div>
              <Stext
                v-if="k == 1"
                size="4"
                ele="p"
                top="5"
                bottom="10"
                align="center"
                indent="true"
                >{{ val }}</Stext
              >
              <div v-if="k == 2">
                <STable :data="getTable(val)"></STable>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Stext from "./text.vue";
import STable from "./table.vue";
import STable_three from "./table_three.vue";
export default {
  components: {
    Stext,
    STable,
    STable_three,
  },
  props: {
    data: Object,
  },
  data() {
    return {};
  },
  created() {},
  updated() {},
  methods: {
    getTable(table) {
      let header = [
        [
          { val: table[0][0], colspan: 1, rowspan: 2 },
          { val: table[0][1], colspan: 3, rowspan: 1 },
          { val: table[0][2], colspan: 3, rowspan: 1 },
        ],
        [
          { val: "金额" },
          { val: "占比（%）" },
          { val: "变动率（%）" },
          { val: "金额" },
          { val: "占比（%）" },
          { val: "变动率（%）" },
        ],
      ];
      let body = [];
      table.map((item, i) => {
        if (i !== 0) {
          body.push(item);
        }
      });

      return {
        header,
        body,
      };
    },
  },
  computed: {},
};
</script>

<style lang="less" scoped></style>
