<template>
  <table
    border="1"
    style="
      width: 100%;
      font-size: 14pt;
      color: #333;
      background-color: transparent;
      border-collapse: collapse;
    "
  >
    <thead>
      <tr>
        <th
          style="background: #c7daf1"
          class="th"
          v-for="(tr, i) in data[0]"
          :key="i"
        >
          {{ tr }}
        </th>
      </tr>
    </thead>

    <tbody v-for="(tr, i) in data" :key="i">
      <tr v-if="i !== 0">
        <td
          v-for="(td, k) in tr"
          :key="k"
          :style="
            k == 0
              ? {
                  padding: '5px',
                  border: '1px solid #333',
                  'text-align': 'left',
                }
              : {
                  padding: '5px',
                  border: '1px solid #333',
                  'text-align': 'right',
                }
          "
        >
          {{ td }}
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  components: {},
  props: {
    data: Array,
    borderColor: {
      type: String,
      default: "#000",
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped>
.th {
  padding: 5px;
  border: 1px solid #333;
  font-weight: bold;
  background: #c7daf1;
  vertical-align: middle;
  text-align: center;
}
</style>
