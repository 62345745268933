<template>
  <div style="background: #fff; height: 100vh; overflow-y: auto">
    <!-- 粉饰风险报告 -->
    <!-- <whitewash-riskReport
      v-if="checkboxGroup.includes('通用财务分析报告')"
    ></whitewash-riskReport> -->
    <!-- 信贷图文版报告 信贷图文版报告附表 -  -->
    <!-- <credit-financial-analysis-report
      v-if="checkboxGroup.includes('信贷图文版报告')"
    ></credit-financial-analysis-report> -->

    <!-- 信贷表格版报告 -->
    <!-- <credit-form-report
      v-if="checkboxGroup.includes('信贷表格版报告')"
    ></credit-form-report> -->

    <!-- 小微企业信贷报告 -->

    <!-- <micro-report v-if="checkboxGroup.includes('小微企业信贷报告')"></micro-report> -->
    <!-- 信贷财务分析报告 -->
    <!-- <analysis-report v-if="checkboxGroup.includes('信贷财务分析报告A')"></analysis-report> -->

    <!-- 信贷财务分析报告B -->
    <!-- <analysis-report-b
      v-if="checkboxGroup.includes('信贷财务分析报告B')"
    ></analysis-report-b> -->

    <!-- 信贷专业版报告 -->
    <!-- <credit-report v-if="checkboxGroup.includes('一般企业信贷分析报告')"></credit-report> -->

    <!-- 事业单位报告 -->
    <!-- <cause-report v-if="checkboxGroup.includes('事业单位信贷报告')"></cause-report> -->
    <ReportIndustry
      v-if="checkboxGroup.includes('行业比较分析报告')"
    ></ReportIndustry>
    <ReportQuality
      v-if="checkboxGroup.includes('报表质量分析报告')"
    ></ReportQuality>
    <credit-reports
      v-if="checkboxGroup.includes('一般企业信贷分析报告')"
    ></credit-reports>
    <credit-report
      v-if="checkboxGroup.includes('专用信贷版分析报告')"
    ></credit-report>
    <ReportFinancial
      v-if="checkboxGroup.includes('财务分析主报告')"
    ></ReportFinancial>
  </div>
</template>
<script>
export default {
  components: {
    // CreditFinancialAnalysisReport: require("./creditFinancialAnalysisReport.vue").default,
    // CreditFormReport: require("./creditFormReport.vue").default,
    // WhitewashRiskReport: require("./WhitewashRiskReport").default,
    // MicroReport: require("./MicroReport.vue").default,
    // AnalysisReport: require("./AnalysisReport/index.vue").default,
    // AnalysisReportB: require("./AnalysisReportB/index.vue").default,
    // CauseReport: require("./CauseReport/index.vue").default,
    ReportIndustry: require("./ReportIndustry/index.vue").default,
    ReportQuality: require("./ReportQuality/index.vue").default,
    CreditReport: require("./CreditReport/index.vue").default,
    CreditReports: require("./CreditReports/index.vue").default,
    ReportFinancial: require("./ReportFinancial/index.vue").default,
  },
  data() {
    return {
      checkboxGroup: [],
    };
  },
  created() {
    this.checkboxGroup = this.$route.query.checkboxGroup;
  },
};
</script>

<style lang="less">
.need_border {
  * {
    border-color: #000;
  }
}
.imgBox {
  width: 400px !important;
}
.imgBox6 {
  width: 600px !important;
}
.biao {
  width: 1100px;
  // height: 999px;
  background: #fff;
  margin: 0 auto;
  padding: 50px 18px;
  .contentBox3 {
    // border-top: 1px solid #ccc;
    padding-top: 30px;
    margin-top: 30px;
  }

  > .box {
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px #ccc;
    overflow: hidden;
    padding-bottom: 58px;

    > .btn {
      text-align: right;
      // margin-top:20px;
      padding-right: 16px;
      padding-top: 20px;
    }
    .content {
      width: 80%;
      margin: 0 auto;
      cursor: default;
      > h3 {
        text-align: left;
        margin-top: 30px;
      }
      .msg {
        width: 100%;
        padding: 16px 30px;
        p {
          text-align: left;
        }
      }
      > .bar-box {
        width: 100%;
        overflow: hidden;
        margin-top: 30px;
        > div {
        }
        .left-bar {
          float: left;
          width: 60%;
        }
        .right-bar {
          width: 40%;

          float: left;
        }
        .thr-line {
          width: 80%;
          margin: 0 auto;
          height: 259px;
        }
      }
    }
    .s {
      margin-top: 50px;
    }
  }
}
.footerBtnBox {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0 0 8px #ccc;
}
.footerBtn {
  width: 1064px;

  text-align: right;

  margin: 0 auto;
  height: 50px;
  background: #fff;
  bottom: 0;
  padding: 9px 25px;
  text-align: right;

  el-button {
    margin-left: 66px;
  }
}
.back {
  position: fixed;
  left: 10px;
  top: 10px;
  cursor: pointer;
}
.txt {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.tetd {
  text-align: left;
}
</style>
