var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content mt-3",staticStyle:{"color":"#333"}},[_c('s-text',{attrs:{"size":"2","weight":"700","ele":"h1","top":"50","bottom":"20"}},[_vm._v("附录")]),_c('s-text',{attrs:{"size":"7","ele":"p","top":"5","indent":"true"}},[_vm._v("本报告采用先总后分的方式，先对该企业财报分析过程中反映出来的风险水平进行评估评价，形成结论；然后再逐项进行分析。以下为重点概念的解释： ")]),_c('h2',{staticStyle:{"font-size":"14pt","font-weight":"800","margin-top":"30px","margin-bottom":"30px","color":"#333"}},[_vm._v(" 1、报表质量 ")]),_c('s-text',{attrs:{"size":"7","ele":"p","top":"5","indent":"true"}},[_vm._v(" 根据专家规则及模型评判出报表质量的可信程度。财务报表质量程度区间分为：基本可信，少量存疑和明显异常。 ")]),_vm._m(0),_vm._m(1),_vm._m(2),_c('h2',{staticStyle:{"font-size":"14pt","font-weight":"800","margin-top":"30px","margin-bottom":"30px","color":"#333"}},[_vm._v(" 2、财务能力 ")]),_c('s-text',{attrs:{"size":"7","ele":"p","top":"5","indent":"true"}},[_vm._v(" 财务能力是从企业五大财务能力出发，对其相关的能力进行评价分析。本报告将目标企业的财务能力按从低到高排序，共分为五档，即：能力低、能力较低、能力中、能力较高和能力高。 ")]),_c('h2',{staticStyle:{"font-size":"14pt","font-weight":"800","margin-top":"30px","margin-bottom":"30px","color":"#333"}},[_vm._v(" 3、财务风险 ")]),_c('s-text',{attrs:{"size":"7","ele":"p","top":"5","indent":"true"}},[_vm._v(" 财务风险是从企业财务状况整体能力出发，对三大报表扫描分析后形成风险结论，供行内相关人员参考。本报告将目标企业的财务能力分为三档，即：风险高、风险中和风险低。 ")]),_vm._m(3),_vm._m(4),_vm._m(5)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","align-items":"center","font-size":"12pt","line-height":"20px","margin-top":"10px"}},[_c('p',[_c('b',[_vm._v("(1)基本可信：")]),_vm._v("在财报质量分析中，没有发现报表中存在若干或大量或重大的异常情况，说明企业提供的报表为基本可信状态。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","align-items":"center","font-size":"12pt","line-height":"20px","margin-top":"10px"}},[_c('p',[_c('b',[_vm._v("(2)少量存疑：")]),_vm._v("在财报质量分析分析中，发现报表中存在若干异常情况，建议企业进一步提供补充信息来解释相关的异常情况，从而进一步提高报表的真实可信程度。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","align-items":"center","font-size":"12pt","line-height":"20px","margin-top":"10px"}},[_c('p',[_c('b',[_vm._v("(3)明显异常：")]),_vm._v("在财报质量分析中，发现报表中存在大量或重大的异常情况，需要企业进一步提供补充信息来解释相关异常情况，从而进一步判断报表的真实性。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","align-items":"center","font-size":"12pt","line-height":"20px","margin-top":"10px"}},[_c('p',[_c('b',[_vm._v("(1)风险高：")]),_vm._v("评估结果为高，表明对于报表中反映的风险点数量及程度要“重点关注” ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","align-items":"center","font-size":"12pt","line-height":"20px","margin-top":"10px"}},[_c('p',[_c('b',[_vm._v("(2)风险中：")]),_vm._v("评估结果为中，表明对于报表中反映的风险点数量及程度“需要关注” ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","align-items":"center","font-size":"12pt","line-height":"20px","margin-top":"10px"}},[_c('p',[_c('b',[_vm._v("(3)风险低：")]),_vm._v("评估结果为低，表明对于报表中反映的风险点数量及程度“相对可控” ")])])
}]

export { render, staticRenderFns }