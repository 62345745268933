<template>
  <div id="biao">
    <div class="box" style="font-family: SimSun">
      <div id="contentBox2" style="font-family: SimSun">
        <!-- <First :first="first"></First> -->
        <One :first="first" :data="one"></One>
        <!-- <Paging></Paging> -->
        <Two :data="two"></Two>
        <!-- <Paging></Paging> -->
        <Three :data="three"></Three>
        <!-- <Paging></Paging> -->
        <Four :data="four"></Four>
        <!-- <Paging></Paging> -->
        <Five :data="five"></Five>
        <!-- <Paging></Paging> -->
        <Six :data="Six"></Six>
        <!-- <Paging></Paging> -->
        <!-- <Seven :data="Seven"></Seven> -->
        <!-- <Paging></Paging> -->
        <!-- <Eight :data="Eight"></Eight> -->
        <!-- <Paging></Paging> -->
        <!-- <Nine :data="Nine"></Nine> -->
        <!-- <Paging></Paging> -->
        <!-- <Ten :data="Ten"></Ten> -->
        <!-- <Paging></Paging> -->
        <!-- <One_One_HaHa :data="One_One_HaHa"></One_One_HaHa> -->
        <!--   <Paging></Paging> -->
        <Appendix></Appendix>
        <!--<Paging></Paging>
        <AttachedPages :data="tableList"></AttachedPages> -->
      </div>
    </div>
  </div>
</template>

<script>
import { publicDownPDF } from "@/utils.js";
import saveAs from "../js/FileSaver";
import htmlDocx from "../js/html-docx.js";
import { main_report } from "@/api/downWrod";
/* 下载word */
import Stext from "./commponts/text.vue";
// import First from "./commponts/first.vue";
import One from "./commponts/one.vue";
import Two from "./commponts/two.vue";
import Three from "./commponts/three.vue";
import Four from "./commponts/four.vue";
import Five from "./commponts/five.vue";
import Six from "./commponts/six.vue";
// import Seven from "./commponts/seven.vue";
// import Eight from "./commponts/eight.vue";
// import Nine from "./commponts/nine.vue";
// import Ten from "./commponts/ten.vue";
// import One_One_HaHa from "./commponts/One_One_HaHa.vue";
import Appendix from "./commponts/Appendix.vue";
// import AttachedPages from "./commponts/AttachedPages.vue";
import Paging from "./commponts/Paging/index.vue";

export default {
  components: {
    // First,
    One,
    Two,
    Three,
    Four,
    Five,
    Six,
    // Seven,
    // Eight,
    // Nine,
    // Ten,
    // One_One_HaHa,
    // AttachedPages,
    Appendix,
    Paging,
    Stext,
  },

  data() {
    return {
      first: {},
      one: {},
      two: {},
      three: {},
      four: {},
      five: {},
      Six: {},
      Seven: {},
      Eight: {},
      Nine: {},
      Ten: {},
      One_One_HaHa: {},
      tableList: {},
      report_type: 0,
      file_type: "pdf",
    };
  },
  created() {
    setTimeout(this.setData.bind(this), 0);
  },

  methods: {
    async setData() {
      let downWord = JSON.parse(sessionStorage.downWord);
      this.downWord = downWord;
      if (!downWord) return false;
      this.$loadingShow();
      const { data } = await main_report(downWord);
      this.first = data.Data[0];
      this.one = data.Data[1];
      this.two = data.Data[2];
      this.three = data.Data[3];
      this.four = data.Data[4];
      this.five = data.Data[5];
      this.Six = data.Data[6];
      // this.Seven = data.Data[7];
      // this.Eight = data.Data[8];
      // this.Nine = data.Data[9];
      // this.Ten = data.Data[10];
      // this.One_One_HaHa = data.Data[11];

      this.$loadingHide();
      setTimeout(() => {
        this.wordDown();
        this.pdfDown();
      }, 4e3);
    },

    wordDown() {
      if (this.$route.query.status != 2) return false;
      setTimeout(() => {
        var contentDocument = document.getElementById("biao");
        var content =
          "<!DOCTYPE html>" +
          `<head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"></head>` +
          contentDocument.outerHTML;
        var converted = htmlDocx.asBlob(content);
        saveAs(
          converted,
          `财务分析主报告${this.downWord.company_name}${this.downWord.year}`
        );

        setTimeout(() => {
          window.parent.dialog.closeLoading(this.$route.query.checkboxGroup);
        }, 1e3);
      }, 4e3);
    },
    pdfDown() {
      if (this.$route.query.status == 0) {
        const { company_name, year } = this.downWord;

        setTimeout(() => {
          publicDownPDF.call(
            this,
            `财务分析主报告${company_name}${year}`,
            "a",
            [{ id: "biao" }]
          );
          setTimeout(() => {
            window.parent.dialog.closeLoading(this.$route.query.checkboxGroup);
          }, 1e3);
        }, 4e3);
      }
    },
  },
};
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
  line-height: 1;
}
p {
  line-height: 1;
}
#biao {
  .none {
    display: none;
  }
  width: 1200px;
  // box-shadow: 0px 0px 5px #999;
  background: #fff;
  margin: 0 auto;
  padding: 50px 18px;

  > .box {
    width: 100%;
    height: 100%;
    // box-shadow: 0 0 8px #ccc;
    overflow: hidden;
    padding-bottom: 58px;

    > .btn {
      text-align: right;
      // margin-top:20px;
      padding-right: 16px;
      padding-top: 20px;
    }
    .content {
      width: 80%;
      margin: 20px auto;
      page-break-after: always;
      td,
      th {
        padding: 3px 0;
      }

      cursor: default;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        line-height: 1.4;
      }
      p {
        margin-bottom: 10px;
      }
      > h3 {
        text-align: left;
        margin-top: 30px;
      }
      .msg {
        width: 100%;
        padding: 16px 30px;
        p {
          text-align: left;
        }
      }
      > .bar-box {
        width: 100%;
        overflow: hidden;
        margin-top: 30px;
        > div {
        }
        .left-bar {
          float: left;
          width: 60%;
        }
        .right-bar {
          width: 40%;

          float: left;
        }
        .thr-line {
          width: 80%;
          margin: 0 auto;
          height: 259px;
        }
      }
    }
    .s {
      margin-top: 50px;
    }

    .footerBtn {
      // padding-right: 71px;
      width: 100vw;
      text-align: center;
      position: fixed;
      height: 60px;
      line-height: 60px;
      padding: 0 107px;
      bottom: 0;
      background: #fff;
      left: 0;
      box-shadow: 0 0 8px #ccc;
    }
  }
}
</style>
