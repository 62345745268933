<template>
  <div class="content">
    <Stext size="2" ele="h1" align="center" weight="800" top="60" bottom="50">{{
      data.title1
    }}</Stext>
    <div v-for="(item, index) in data.content" :key="index">
      <template v-if="index == 0">
        <h2 style="font-size: 12pt; font-weight: 800; color: #000">
          {{ item.title2 }}
        </h2>
        <h3 style="font-size: 12pt; font-weight: 800; color: #000">
          {{ item.content[0].title3 }}
        </h3>
        <Stext ele="p" align="right">(单位：万元、%)</Stext>
        <div :style="imngCenter ? `text-align: center;` : ''">
          <div style="width: 600px">
            <div class="row" style="width: 100%">
              <div
                class="row mb_box_ch justify-content-between color-0"
                style="width: 100%"
                id="imgBox"
                ref="imgBox"
              >
                <div class v-for="(data, i) in getRateData" :key="i">
                  <div class="text-center" style="margin-bottom: 15px">
                    <div class="color-4d">{{ data.year }}资产总计</div>
                  </div>
                  <div class="row" style="height: 160px">
                    <div
                      class="ml-2px mb_count_ch"
                      style="width: 80px; font-size: 14px"
                    >
                      <div
                        class="text-center row align-items-center justify-content-center"
                        :style="{
                          height: data.f[0].rate,
                          backgroundColor: data.f[0].color,
                        }"
                      >
                        <div style="position: relative; z-index: 10">
                          {{ data.f[0].rate }}
                        </div>
                      </div>
                      <div
                        class="text-center row align-items-center justify-content-center"
                        :style="{
                          height: data.f[1].rate,
                          backgroundColor: data.f[1].color,
                        }"
                      >
                        <div style="position: relative; z-index: 10">
                          {{ data.f[1].rate }}
                        </div>
                      </div>
                    </div>
                    <div
                      class="ml-2px mb_count_ch"
                      style="width: 80px; font-size: 14px"
                    >
                      <div
                        class="text-center row align-items-center justify-content-center"
                        :style="{
                          height: heightRate(data, data.f[2].rate),
                          backgroundColor: data.f[2].color,
                        }"
                      >
                        <div style="position: relative; z-index: 10">
                          {{ data.f[2].rate }}
                        </div>
                      </div>
                      <div
                        class="text-center row align-items-center justify-content-center"
                        :style="{
                          height: heightRate(data, data.f[3].rate),
                          backgroundColor: data.f[3].color,
                        }"
                      >
                        <div style="position: relative; z-index: 10">
                          {{ data.f[3].rate }}
                        </div>
                      </div>
                      <div
                        class="text-center row align-items-center justify-content-center"
                        :style="{
                          height: heightRate(data, data.f[4].rate),
                          backgroundColor: data.f[4].color,
                        }"
                      >
                        <div style="position: relative; z-index: 10">
                          {{ data.f[4].rate }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="text-center">
                    <div
                      class="color-1f text-center"
                      style="width: 100%; margin-top: 20px"
                    >
                      {{ thousandBitSeparator(data.total) }}{{ unitText }}
                    </div>
                  </div>
                </div>
                <div class="row w-100">
                  <div
                    :style="x >= 4 ? 'width: 50%;' : 'width: 30%;'"
                    class="label-text"
                    v-for="(d, x) in seriers"
                    :key="x"
                  >
                    <span
                      :style="{ backgroundColor: d.color }"
                      class="d-inline-block color-block"
                    ></span>
                    <span
                      class="fz-12 ml-2"
                      style="color: #000; vertical-align: 2px"
                      >{{ d.title }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Stext size="10" ele="p" top="20" indent="true">{{
          item.content[0].content[1]
        }}</Stext>
        <Stext size="10" ele="p" top="5" indent="true">{{
          item.content[0].content[2]
        }}</Stext>
        <h3 style="font-size: 12pt; font-weight: 800; color: #000">
          {{ item.content[1].title3 }}
        </h3>
        <Stext ele="p" align="right">(单位：万元、%)</Stext>
        <STable
          style="margin-top: 20px"
          :data="getTableOne(item.content[1].content[0])"
        ></STable>
        <Stext
          size="10"
          ele="p"
          top="10"
          indent="true"
          v-for="i in item.content[1].content[1]"
          :key="i"
          >{{ i }}</Stext
        >
        <!-- <h3 style="font-size: 12pt; font-weight: 800; color: #000">
          {{ item.content[2].title3 }}
        </h3>
        <Stext size="10" ele="p" top="20" indent="true">{{
          item.content[2].content[0][0]
        }}</Stext> -->
        <!-- <Stext ele="p" align="right">(单位：万元、%)</Stext>
        <STable
          style="margin-top: 20px"
          :data="getTableOne(item.content[2].content[1])"
        ></STable>
        <div
          style="font-size: 10.5pt; margin-top: 10px; color: #000"
          v-for="(ele, i) in item.content[2].content[2]"
          :key="i"
        >
          <Stext
            size="10"
            ele="p"
            top="10"
            :style="i == 0 ? 'font-weight: 800;' : ''"
            >{{ i > 0 ? i + "、" : "" }}{{ ele }}
          </Stext>
        </div> -->
      </template>
      <template v-if="index == 1">
        <h2
          style="
            font-size: 12pt;
            font-weight: 800;
            color: #000;
            margin-top: 30px;
          "
        >
          {{ item.title2 }}
        </h2>
        <!-- <h3 style="font-size: 12pt; font-weight: 800; color: #000">
          {{ item.content[0].title3 }}
        </h3> -->
        <Stext ele="p" align="right">(单位：万元、%)</Stext>
        <STable
          style="margin-top: 20px"
          :data="getTableOne(item.content[0].content[0])"
        ></STable>
        <Stext
          size="10"
          ele="p"
          top="20"
          indent="true"
          v-for="i in item.content[0].content[1]"
          :key="i"
          >{{ i }}</Stext
        >
        <!-- <h3
          style="
            font-size: 12pt;
            font-weight: 800;
            color: #000;
            margin-top: 30px;
          "
        >
          {{ item.content[1].title3 }}
        </h3> -->
        <!-- <Stext size="10" ele="p" top="20" indent="true">{{
          item.content[1].content[0][0]
        }}</Stext> -->
        <!-- <Stext ele="p" align="right">(单位：万元、%)</Stext> -->
        <!-- <STable
          style="margin-top: 20px"
          :data="getTableOne(item.content[1].content[1])"
        ></STable> -->
        <!-- <p
          style="
            font-size: 10.5pt;
            margin-top: 30px;
            color: #000;
            font-family: 'SimSun';
            font-weight: 800;
          "
        >
          主要变动科目分析如下：
        </p>
        <div
          style="font-size: 10.5pt; margin-top: 10px; color: #000"
          v-for="(ele, i) in item.content[1].content[2]"
          :key="i"
        >
          <Stext size="10" ele="p" top="10">{{ i + 1 + "、" }}{{ ele }}</Stext>
        </div>
        <h3
          style="
            font-size: 12pt;
            font-weight: 800;
            color: #000;
            margin-top: 30px;
          "
        >
          {{ item.content[2].title3 }}
        </h3>
        <Stext ele="p" align="right">(单位：万元、%)</Stext>
        <STable
          :showLine="true"
          style="margin-top: 20px"
          :data="item.content[2].content.table"
        ></STable>
        <Stext size="10" ele="p" top="20" indent="true">{{
          item.content[2].content.desc.desc[0]
        }}</Stext> -->
      </template>
      <template v-if="index == 2">
        <h2 class="mt-3" style="font-size: 12pt; font-weight: 800; color: #000">
          {{ item.title2 }}
        </h2>
        <Stext size="10" ele="p" top="20" indent="true">{{
          item.content[0]
        }}</Stext>
        <Stext ele="p" align="right">(单位：万元、%)</Stext>
        <STable
          style="margin-top: 20px"
          :data="getTableOne(item.content[2])"
        ></STable>
        <Stext size="10" ele="p" top="20" indent="true">{{
          item.content[3]
        }}</Stext>
        <Stext size="10" ele="p" top="5" indent="true">{{
          item.content[4]
        }}</Stext>
        <Stext size="10" ele="p" top="5" indent="true">{{
          item.content[5]
        }}</Stext>
        <Stext size="10" ele="p" top="5" indent="true">{{
          item.content[6]
        }}</Stext>
        <Stext size="10" ele="p" top="5" indent="true">{{
          item.content[7]
        }}</Stext>
      </template>
      <template
        v-if="
          index == 3 &&
          item.content[0] !== '现流表数据缺失，无法分析，请上传完整数据。'
        "
      >
        <h2 class="mt-3" style="font-size: 12pt; font-weight: 800; color: #000">
          {{ item.title2 }}
        </h2>
        <Stext ele="p" align="right">(单位：万元、%)</Stext>
        <STable
          style="margin-top: 20px"
          :data="getTableOne(item.content[1])"
        ></STable>
        <Stext size="10" ele="p" top="20" indent="true">{{
          item.content[2]
        }}</Stext>
        <Stext size="10" ele="p" top="5" indent="true">{{
          item.content[3]
        }}</Stext>
      </template>
    </div>
  </div>
</template>

<script>
import Stext from "./text.vue";
import STable from "./table";
import html2canvas from "html2canvas";
export default {
  components: {
    Stext,
    STable,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      unitText: "万",
      RateArray: {},
      seriers: {},
      imngCenter: false,
    };
  },
  created() {},
  updated() {
    setTimeout(this.toImg.bind(this), 0);
  },
  methods: {
    heightRate(data, nowRate) {
      let heightRate = nowRate;
      let tatol = 0;
      data.f.forEach((item, i) => {
        if (i > 1) {
          tatol += Math.abs(parseFloat(item.rate.split("%")));
        }
      });
      if (Math.round(tatol) > 100) {
        heightRate =
          (
            Math.abs(parseFloat(nowRate.split("%"))) / Math.round(tatol)
          ).toFixed(2) *
            100 +
          "%";
      }
      return heightRate;
    },
    thousandBitSeparator(num) {
      return (
        num &&
        (num.toString().indexOf(".") != -1
          ? num.toString().replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
              return $1 + ",";
            })
          : num.toString().replace(/(\d)(?=(\d{3})+$)/g, function ($0, $1) {
              return $1 + ",";
            }))
      );
    },
    getTableOne(table) {
      let header = [];
      let body = [];
      let idx = 0;
      table.forEach((item, index) => {
        if (index == 0) {
          header[0] = [];
          item.forEach((ele) => {
            header[idx].push({
              val: ele,
              style: "font-weight: 700; text-align: right;",
            });
            delete header[idx][0].style;
          });
        } else {
          body[idx] = [];
          item.forEach((ele) => {
            body[idx].push({
              val: ele,
              style: "text-align: right;",
            });
            delete body[idx][0].style;
          });
          idx++;
        }
      });
      return {
        header,
        body,
      };
    },
    toImg() {
      if (this.$route.query.status == 0) return false;
      const that = this;
      const div = this.$refs.imgBox;
      const imgBox = document.querySelector("#imgBox");
      html2canvas(imgBox).then(function (canvas) {
        imgBox.innerHTML = "";
        let pageData = canvas.toDataURL();
        let img = new Image();

        img.src = pageData;
        img.width = "600";
        img.height = "291";
        imgBox.innerHTML = "";
        imgBox.appendChild(img);
        that.imngCenter = true;
      });
    },
  },
  computed: {
    getRateData: function () {
      let set = [];

      let u = this.data.content[0].content[0].content[0].FB.content;
      set[0] = u[1];
      set[1] = u[2];
      set[2] = u[3];
      set[3] = u[4];
      set[4] = u[5];
      this.RateArray = set;
      let c = u[0].value;
      let year = this.data.content[0].content[0].content[0].FB.year;
      let YL = year.length;
      let RL = set.length;
      this.colorArray = [
        "#1989FA",
        "#3AC2EF",
        "rgba(25, 137, 250, 0.7)",
        "#18E1A8",
        "#FEC400",
      ];
      let a = [];
      let b = [];
      let d = [];
      var writerInfo = {};
      for (var i = 0; i < YL; i++) {
        a[i] = {};
        a[i].f = [];
        for (var j = 0; j < RL; j++) {
          a[i].f[j] = {};
          this.seriers[j] = {};
          a[i].total = c[i];
          a[i].year = year[i];
          a[i].f[j].title = set[j].title1;
          a[i].f[j].rate = set[j].rate[i];
          a[i].f[j].color = this.colorArray[j];
          this.seriers[j].color = this.colorArray[j];
          this.seriers[j].title = this.RateArray[j].title1;
        }
      }

      a.forEach((v, i) => {
        //
        const num = 100 - parseFloat(v.f[0].rate.split("%")[0]);
        v.f[1].rate = Math.round(num * 100) / 100 + "%";
      });
      return a;
    },
  },
};
</script>

<style lang="less" scoped>
.color-block {
  width: 12px;
  height: 12px;
}

.mb_count_ch {
  margin: 1px;

  > div {
    margin: 2px;
  }
}

.label-text {
  float: left;
}
</style>
