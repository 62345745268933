<template>
  <div class="content">
    <s-text
      size="2"
      ele="h1"
      align="center"
      weight="800"
      top="60"
      bottom="50"
      >{{ data.title1 }}</s-text
    >
    <!-- 4.1 -->
    <div>
      <h2 style="font-size: 12pt; font-weight: 800; color: #000">
        {{ data.content[0].title2 }}
      </h2>
      <s-text
        size="10"
        ele="p"
        top="20"
        indent="true"
        v-for="(item, i) in data.content[0].content[0].desc"
        :key="i"
      >
        {{ item }}
      </s-text>
    </div>
    <!-- 4.2 -->
    <div class="mt-2">
      <h2 style="font-size: 12pt; font-weight: 800; color: #000">
        {{ data.content[1].title2 }}
      </h2>
      <div v-for="(item, i) in data.content[1].content" :key="i">
        <s-text
          size="10"
          ele="p"
          top="20"
          indent="true"
          v-for="(item1, i) in item"
          :key="i"
        >
          {{ item1 }}
        </s-text>
      </div>
    </div>
    <!-- 4.3 -->
    <div class="mt-2">
      <h2 style="font-size: 12pt; font-weight: 800; color: #000">
        {{ data.content[2].title2 }}
      </h2>
      <s-text
        size="10"
        ele="p"
        top="20"
        indent="true"
        v-for="(item1, i) in data.content[2].content[0]"
        :key="i"
      >
        {{ item1 }}
      </s-text>
    </div>
    <!-- 4.4 -->
    <div class="mt-2">
      <h2 style="font-size: 12pt; font-weight: 800; color: #000">
        {{ data.content[3].title2 }}
      </h2>
      <s-text
        size="10"
        ele="p"
        top="20"
        indent="true"
        v-for="(item1, i) in data.content[3].content[0]"
        :key="i"
      >
        {{ item1 }}
      </s-text>
    </div>
    <!-- 4.5 -->
    <div class="mt-2" v-if="data.content[4].title2 !== undefined">
      <h2 style="font-size: 12pt; font-weight: 800; color: #000">
        {{ data.content[4].title2 }}
      </h2>
      <s-text
        size="10"
        ele="p"
        top="20"
        indent="true"
        v-for="(item1, i) in data.content[4].content[0]"
        :key="i"
      >
        {{ item1 }}
      </s-text>
    </div>
    <!-- 4.6 -->
    <div class="mt-2">
      <h2 style="font-size: 12pt; font-weight: 800; color: #000">
        {{ data.content[5].title2 }}
      </h2>
      <div ref="ele" style="height: 350px"></div>
      <s-text ele="h2" top="20" indent="true" weight="800">
        {{ data.content[5].content[0].note }}
      </s-text>
      <s-text
        size="10"
        ele="p"
        top="20"
        indent="true"
        v-for="(item, i) in data.content[5].content[0].desc"
        :key="i"
      >
        {{ item }}
      </s-text>
    </div>
  </div>
</template>

<script>
import SText from "./text";
import STable from "./table";
import html2canvas from "html2canvas"; //文本转图片
export default {
  components: {
    SText,
    STable,
  },
  props: {
    data: Object,
  },
  data() {
    return {};
  },
  computed: {},
  updated() {},
  mounted() {
    this.sysTemRuleChart();

    this.init();
  },

  methods: {
    sysTemRuleChart() {
      // 获取dom
      let dataList = this.data.content[0].content[0].char;
      let colorList = [
        "#10BA34", //绿色
        "#FEC400", //黄橙色
        "#F72222", //红色
        // "#DDDBD2", //灰色
      ];
      let optionOne = {
        graphic: [
          {
            type: "group",
            left: "center",
            top: "middle",
            children: [
              {
                type: "text",
                z: 100,
                left: "center",
                top: "0",
                style: {
                  fill: "#ccc",
                  text: ["财务风险"],
                  font: "16px Microsoft YaHei",
                },
              },
              {
                type: "text",
                z: 100,
                left: "center",
                top: "32",
                style: {
                  fill: "#000",
                  text: dataList.overall_risk,
                  font: "18px Microsoft YaHei",
                },
              },
            ],
          },
        ],
        series: [
          // 主要展示层的
          {
            data: dataList.detail,
            radius: ["16%", "30%"],
            center: ["50%", "50%"],
            type: "pie",
            itemStyle: {
              normal: {
                color: function (params) {
                  return colorList[params.dataIndex];
                },
              },
            },
            // 饼图线条
            labelLine: {
              normal: {
                show: true,
                length: 5,
                length2: 80,
                lineStyle: {
                  color: "#d3d3d3",
                },
                // align: "right",
              },
              color: "#000",
              emphasis: {
                show: true,
              },
            },
            label: {
              normal: {
                formatter: function (params) {
                  // console.log(params);
                  // 每两个分为一个新数组
                  var arrlist = [];
                  function chunk(arr, num) {
                    let j = 0,
                      o = j;
                    let newArray = [];
                    while (j < arr.length) {
                      j += num;
                      newArray.push(arr.slice(o, j));
                      o = j;
                    }
                    arrlist = newArray;
                  }
                  chunk(params.data.info, 2);

                  var str = "";
                  switch (params.data.name) {
                    // case "无法计算":
                    //   str =
                    //     "{a|} {nameStyle|" +
                    //     params.data.name +
                    //     "} {rate|" +
                    //     params.percent +
                    //     "%}\n{zhibiao|" +
                    //     params.value +
                    //     "}个指标" +
                    //     "\n{textStyle|" +
                    //     arrlist.join("\n") +
                    //     "}";
                    //   break;
                    case "较差值<当前值<优秀值":
                      str =
                        " {b|} {nameStyle|" +
                        params.data.name +
                        "} {rateStylesa|" +
                        params.percent +
                        "%}" +
                        "\n{zhibiao|" +
                        params.value +
                        "}个指标" +
                        "\n{textStyle|" +
                        arrlist.join("\n") +
                        "}";
                      break;
                    case "当前值≥优秀值":
                      str =
                        "{c|} {nameStyle|" +
                        params.data.name +
                        "} {rateStyles|" +
                        params.percent +
                        "%}\n{zhibiao|" +
                        params.value +
                        "}个指标" +
                        "\n{textStyle|" +
                        arrlist.join("\n") +
                        "}";
                      break;
                    case "当前值≤较差值":
                      str =
                        "                 {d|} {nameStyle|" +
                        params.data.name +
                        "} {rateStyle|" +
                        params.percent +
                        "%}\n{zhibiao|" +
                        params.value +
                        "}个指标" +
                        "\n{textStyle|" +
                        arrlist.join("\n") +
                        "}";
                      break;
                  }
                  return str;
                },
                rich: {
                  // a: {
                  //   width: 14,
                  //   height: 14,
                  //   backgroundColor: "#DDDBD2",
                  //   align: "left",
                  //   borderRadius: 14,
                  // },
                  b: {
                    width: 14,
                    height: 14,
                    backgroundColor: "#FEC400",
                    align: "left",
                    borderRadius: 14,
                  },
                  c: {
                    width: 14,
                    height: 14,
                    backgroundColor: "#10BA34",
                    align: "left",
                    borderRadius: 14,
                  },
                  d: {
                    width: 14,
                    height: 14,
                    backgroundColor: "#F72222",
                    align: "left",
                    borderRadius: 14,
                  },
                  nameStyle: {
                    fontSize: 15,
                    color: "#555",
                    align: "left",
                  },
                  // rate: {
                  //   fontSize: 17,
                  //   color: "#DDDBD2",
                  //   align: "left",
                  // },
                  rateStyle: {
                    fontSize: 17,
                    color: "#F72222",
                    align: "left",
                  },
                  rateStyles: {
                    fontSize: 17,
                    color: "#10BA34",
                    align: "left",
                  },
                  rateStylesa: {
                    fontSize: 17,
                    color: "#FEC400",
                    align: "left",
                  },
                  textStyle: {
                    // fontSize: 12,
                    lineHeight: 18,
                  },
                  zhibiao: {
                    lineHeight: 20,
                  },
                },
              },
            },
          },
          {
            radius: ["30%", "27%"],
            center: ["50%", "50%"],
            type: "pie",
            label: {
              normal: {
                show: false,
              },
              emphasis: {
                show: false,
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
              emphasis: {
                show: false,
              },
            },
            animation: false,
            tooltip: {
              show: false,
            },
            itemStyle: {
              normal: {
                color: "rgba(250,250,250,0.5)",
              },
            },
            data: [
              {
                value: 1,
              },
            ],
          },
        ],
        // 鼠标悬浮提示框
        tooltip: {
          // trigger: 'item',
          formatter: " {b} {d}% <br/>" + " {c}个指标",
        },
      };
      this.$echarts.init(this.$refs.eleOne).setOption(optionOne);
      if (this.$route.query.status == 2) {
        setTimeout(() => {
          this.toImgbox();
          this.toImg1();
        }, 1000);
      }
    },
    toImg1() {
      const base64 = this.$refs.eleOne.querySelector("canvas").toDataURL();
      this.$refs.eleOne.innerHTML = `<img width="620" height="350" src="${base64}" / >`;
    },
    toImgbox() {
      if (this.$route.query.status == 0) return false;
      const imgList = document.querySelector("#imgList");
      html2canvas(imgList).then(function (canvas) {
        let pageData = canvas.toDataURL("image/jpeg");
        let img = new Image();
        img.src = pageData;
        img.width = "620";
        img.height = "18";
        imgList.innerHTML = "";
        imgList.appendChild(img);
      });
    },

    // 4.4
    init() {
      const title = [];
      const data = [];
      if (
        this.data.content[5].content[0].chart &&
        this.data.content[5].content[0].chart.length
      ) {
        for (
          var i = this.data.content[5].content[0].chart.length - 1;
          i >= 0;
          i--
        ) {
          var row = this.data.content[5].content[0].chart[i];
          // console.log(row);
          title.push(row.name);
          data.push(100 - row.pos_val);
        }
      }
      var option = {
        legend: {
          selectedMode: false,
          type: "plain", // 普通图例
          orient: "horizontal", // 水平
          data: [
            "排名前0-10%",
            "排名前10-32.5%",
            "排名前32.5-67.5%",
            "排名前67.5-90%",
            "排名前90-100%",
          ],
          bottom: 5,
        },
        // 位置
        // grid: {
        //   left: "3%",
        //   containLabel: true,
        // },
        xAxis: {
          type: "value",
          show: true,
          min: 0,
          max: 100,
          // minInterval:10,
          // maxInterval:32.5,
          interval: 10,
          axisLine: {
            show: true,
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: [
                "#ccc",
                "#ccc",
                "#FFF",
                "#ccc",
                "#FFF",
                "#FFF",
                "#ccc",
                "#FFF",
                "#FFF",
                "#ccc",
                "#ccc",
              ],
            },
          },
          axisLabel: {
            formatter: function (value, i) {
              // console.log(value,i);
              var texts = [];
              if (i == 1) {
                texts.push("{LabelStyle1|\n\n排名前\n90 - 100%}");
              } else if (i == 2) {
                texts.push("{LabelStyle2|\n\n排名前\n67.5 - 90%}");
              } else if (i == 5) {
                texts.push("{LabelStyle3|\n\n排名前\n32.5 - 67.5%}");
              } else if (i == 8) {
                texts.push("{LabelStyle4|\n\n排名前\n10 - 32.5%}");
              } else if (i == 10) {
                texts.push("{LabelStyle1|\n\n排名前\n0 - 10%}");
              }
              return texts;
            },
            rotate: 0.1,
            // padding: 10,
            rich: {
              LabelStyle1: {
                padding: 10,
                align: "center",
                lineHeight: 15,
              },
              LabelStyle2: {
                padding: -20,
                align: "center",
                lineHeight: 15,
              },
              LabelStyle3: {
                padding: 2,
                align: "center",
                lineHeight: 15,
              },
              LabelStyle4: {
                padding: 2,
                align: "center",
                lineHeight: 15,
              },
            },
          },
        },
        yAxis: {
          type: "category",
          data: title,
          axisLine: {
            show: true,
          },
        },
        series: [
          {
            name: "排名前0-10%",
            type: "bar",
            barWidth: "50%",
            data: data,
            color: "#529EE2",
            itemStyle: {
              normal: {
                color: function (params) {
                  if (params.data <= 10) {
                    return "#F2637B";
                  }
                  if (params.data <= 32.5) {
                    return "#F88E36";
                  }
                  if (params.data > 32.5 && params.data <= 67.5) {
                    return " #FAD33F";
                  }
                  if (params.data <= 90) {
                    return "#3EBB9F";
                  }
                  if (params.data <= 100) {
                    return "#529EE2";
                  }
                },
              },
            },
          },
          {
            name: "排名前10-32.5%",
            type: "bar",
            color: "#3EBB9F",
            barWidth: "0%",
          },
          {
            name: "排名前32.5-67.5%",
            barWidth: "0%",
            type: "bar",
            color: "#FAD33F",
          },
          {
            name: "排名前67.5-90%",
            barWidth: "0%",
            type: "bar",
            color: "#F88E36",
          },
          {
            name: "排名前90-100%",
            barWidth: "0%",
            type: "bar",
            color: "#F2637B",
          },
        ],
      };
      this.$echarts.init(this.$refs.ele).setOption(option);
      if (this.$route.query.status == 2) {
        setTimeout(() => {
          this.toImg();
        }, 1500);
      }
    },
    toImg() {
      const base64 = this.$refs.ele.querySelector("canvas").toDataURL();
      this.$refs.ele.innerHTML = `<img width="600" height="250" src="${base64}" / >`;
    },
  },
  watch: {
    data() {
      setTimeout(this.sysTemRuleChart, 200);
      setTimeout(this.init, 200);
    },
  },
};
</script>
<style lang="less" scoped>
#imgbox {
  border: 1px solid #ccc;
  width: 100%;
  height: 550px;
  display: flex;
  flex-wrap: wrap;
}

.groundcolor {
  width: 28px;
  height: 15px;
  border-radius: 5px;
  margin: 5px;
}

#imgList {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  width: 100%;
  height: 20px;
}
</style>
