<template>
  <div v-if="$route.query.status == 2" v-html="html"></div>
</template>
<script>
export default {
  data() {
    return {
      html: `<span
            lang="EN-US"
            style="font-size: 10.5pt; mso-bidi-font-size: 12pt; font-family: "
            mce_style="font-size:10.5pt;mso-bidi-font-size:12.0pt;font-family:"
            Times
            New
            Roman
            ;mso-fareast-font-family:宋体;mso-font-kerning:1.0pt;mso-ansi-language
            EN-US;mso-fareast-language:ZH-CN;mso-bidi-language:AR-SA
            ><br
              clear="all"
              style="page-break-before: always"
              mce_style="page-break-before:always"
          /></span>`,
    };
  },
};
</script>
