<template>
  <div class="content">
    <s-text
      size="2"
      ele="h1"
      align="center"
      weight="800"
      top="60"
      bottom="50"
      >{{ data.title1 }}</s-text
    >
    <div class="rightTitleText" id="rightTitleText">
      <s-text ele="p" top="20" weight="500">
        在对报表分析过程中,我们发现共有
        <span style="color: #f14c5d; font-size: 18px">{{
          data.content[0].summary.高危.length +
          data.content[0].summary.中低.length
        }}</span>
        个风险，其中
        <span style="color: #f14c5d; font-size: 18px">{{
          data.content[0].summary.高危.length
        }}</span>
        个高危风险、
        <span style="color: #f88e36; font-size: 18px">{{
          data.content[0].summary.中低.length
        }}</span>
        个中低风险。
      </s-text>
      <div class="row mt-4">
        <div style="font-size: 14px; color: #333; width: 10%" class="mt-1">
          高危风险：
        </div>
        <div class="row contentTag">
          <span
            class="tag"
            v-for="(item, index) in data.content[0].summary.高危"
            :key="index"
            @click="href(item[0])"
          >
            {{ item[1] }}
          </span>
        </div>
      </div>
      <div class="row mt-4">
        <div style="font-size: 14px; color: #333; width: 10%" class="mt-1">
          中低风险：
        </div>
        <div class="row contentTag">
          <span
            class="tags"
            v-for="item in data.content[0].summary.中低"
            :key="item"
            @click="href(item[0])"
          >
            {{ item[1] }}
          </span>
        </div>
      </div>
    </div>
    <div>
      <STable
        style="margin-top: 20px"
        :data="getTable(data.content[0].detail)"
      ></STable>
    </div>
  </div>
</template>

<script>
import SText from "./text";
import STable from "./table";
import html2canvas from "html2canvas"; //文本转图片
export default {
  components: {
    SText,
    STable,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      borderColor: {
        type: String,
        default: "#000",
      },
    };
  },
  mounted() {},
  methods: {
    getTable(table) {
      if (this.$route.query.status == 2) {
        setTimeout(() => {
          this.toImgbox();
        }, 1500);
      }
      const header = [
        [
          {
            val: "风险分类",
            style: "background: rgb(199, 218, 241);width:120px",
          },
          {
            val: "风险程度",
            style: "background: rgb(199, 218, 241);width:120px",
          },
          {
            val: "风险简介",
            style: "background: rgb(199, 218, 241);width:200px",
          },
          { val: "风险说明", style: "background: rgb(199, 218, 241);" },
        ],
      ];
      const body = [];

      const data = [];

      Object.entries(table).map((item, index) => {
        let it = {
          title: "",
          list: [],
        };

        Object.entries(item[1]).map((items, i) => {
          // console.log(items);
          let is = {
            title1: "",
            list1: [],
          };
          is.title1 = items[0];
          is.list1 = items[1];
          it.list.push(is);
        });
        it.title = item[0];
        data.push(it);
      });
      let index = 0;

      data.map((items) => {
        items.list.map((item, i) => {
          // console.log(item,i);
          item.list1.map((it) => {
            let ind = index;
            body[ind] = [];
            body[ind].push(
              {
                val: items.title,
                rowspan: 1,
              },
              {
                val: item.title1,
                rowspan: 1,
              },
              {
                val: it.name,
                rowspan: 1,
              }
            );
            it.desc.map((res) => {
              body[ind].push({
                val: res,
                rowspan: 1,
              });
            });
            index++;
          });
        });
      });

      return {
        header,
        body,
      };
    },
    toImgbox() {
      const rightTitleText = document.querySelector("#rightTitleText");
      html2canvas(rightTitleText).then(function (canvas) {
        rightTitleText.innerHTML = "";
        let pageData = canvas.toDataURL("image/jpeg");
        let img = new Image();
        img.src = pageData;
        img.width = "620";
        img.height = "120";
        rightTitleText.appendChild(img);
      });
    },
  },
};
</script>

<style scoped lang="less">
table,
th,
td {
  border: none !important;
}

.rightTitleText {
  width: 100%;
  margin-top: 20px;
  margin-right: 20px;

  .contentTag {
    flex-wrap: wrap;
    width: 90%;

    .tag {
      display: inline-block;
      height: 30px;
      background: rgba(241, 76, 93, 0.12);
      border-radius: 4px;
      padding-left: 5px;
      padding-right: 5px;
      font-size: 14px;
      border: none;
      line-height: 30px;
      color: #f14c5d;
      margin-right: 15px;
      margin-bottom: 15px;
    }

    .tags {
      display: inline-block;
      height: 30px;
      background: rgba(248, 142, 54, 0.12);
      border-radius: 4px;
      padding-left: 5px;
      border: none;
      padding-right: 5px;
      line-height: 30px;
      font-size: 14px;
      color: #f88e36;
      margin-right: 15px;
      margin-bottom: 15px;
    }
  }
}
</style>
