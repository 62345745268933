var render = function render(){var _vm=this,_c=_vm._self._c;return _c('table',{staticStyle:{"width":"100%","background-color":"transparent","border-collapse":"collapse"},attrs:{"border":"1"}},[_c('thead',_vm._l((_vm.data.header),function(tr){return _c('tr',{key:tr},_vm._l((tr),function(th){return _c('th',{key:th,style:(`padding: 5px;border:1px solid ${_vm.borderColor}; font-weight:bold; background: #c7daf1;vertical-align: middle;text-align: center;` +
          th.style),attrs:{"colspan":th.colspan || 1,"rowspan":th.rowspan || 1}},[_c('s-text',{style:('text-align: left;' + th.style),attrs:{"ele":"p","size":"9","weigth":"700"}},[_vm._v(_vm._s(th.val))])],1)}),0)}),0),_c('tbody',_vm._l((_vm.data.body),function(tr,i){return _c('tr',{key:i},_vm._l((tr),function(th){return _c('td',{key:th,style:(th.style
            ? th.style + `padding: 5px;border:1px solid ${_vm.borderColor}`
            : `vertical-align: middle;;padding: 5px;border:1px solid ${_vm.borderColor}`),attrs:{"colspan":th.colspan || 1,"rowspan":th.rowspan || 1,"width":th.width}},[(th.noLine)?[_c('span',{staticStyle:{"font-size":"10pt"},style:(th.style)},[_vm._v(" "+_vm._s(" ")+" "),_c('br'),_vm._v(" "+_vm._s(" ")+" ")])]:[_c('s-text',{style:(th.style +
              `;${
                _vm.get_status(th.status).color
                  ? 'color:' + _vm.get_status(th.status).color
                  : ''
              };${th.val == '--' ? 'text-align: right;' : ''}`),attrs:{"ele":"p","size":"9"}},[(_vm.get_status(th.status).img)?_c('img',{attrs:{"src":_vm.get_status(th.status).img}}):_vm._e(),(_vm.get_status(th.status).img)?_c('img',{attrs:{"src":_vm.get_status(th.status).img}}):_vm._e(),(
                _vm.showLine &&
                (th.status == 3 ||
                  th.status == 7 ||
                  th.status == '+violent' ||
                  th.status == '-violent') &&
                _vm.get_status(th.status).img
              )?_c('img',{attrs:{"src":_vm.get_status(th.status).img}}):_vm._e(),_vm._v(" "+_vm._s(th.val)+" ")])]],2)}),0)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }