<template>
  <div class="content">
    <Stext
      v-for="item in first.cover"
      :key="item"
      size="3"
      ele="h1"
      align="center"
      top="50"
      >{{ item }}</Stext
    >

    <Stext size="6" align="center" weight="700" ele="h1" top="50" bottom="20">{{
      data.title
    }}</Stext>

    <div v-for="(item, index) in data.content" :key="index">
      <div v-if="index == 3">
        <div v-for="(des, i) in item" :key="i">
          <div v-if="i == 0">
            <Stext size="9" ele="p" top="5" indent="true" v-html="des"></Stext>
          </div>
          <Stext v-else size="9" ele="p" top="5" indent="true">{{
            i + "." + des
          }}</Stext>
        </div>
      </div>
      <Stext
        v-else
        size="9"
        ele="p"
        top="5"
        indent="true"
        v-html="item"
      ></Stext>
    </div>
  </div>
</template>

<script>
import Stext from "./text.vue";
export default {
  components: {
    Stext,
  },
  props: {
    first: Object,
    data: Object,
  },
};
</script>

<style lang="less" scoped>
.content {
  p {
    line-height: 1.7;
  }
}
</style>
