<template>
  <div>
    <div class="biao" id="biao" style="padding-top: 0">
      <div class="box">
        <div id="contentBox2">
          <First :downWord="downWord"></First>
          <One :data="one"></One>
          <Paging></Paging>
          <Two :data="two"></Two>
          <Paging></Paging>
          <Three :data="three"></Three>
          <Paging></Paging>
          <Four :data="four"></Four>
          <Paging></Paging>
          <Five :data="five"></Five>
          <Paging></Paging>
          <Appendix></Appendix>
          <Paging></Paging>
        </div>
      </div>
    </div>
    <div class="biao" id="biao2">
      <div class="box">
        <div id="contentBox3">
          <AttachedPages :data="tableList"></AttachedPages>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Stext from "./compontents/text.vue";
import First from "./compontents/first.vue";
import One from "./compontents/one.vue";
import Two from "./compontents/two.vue";
import Three from "./compontents/three.vue";
import Four from "./compontents/four.vue";
import Five from "./compontents/five.vue";
import Appendix from "./compontents/Appendix.vue";
import AttachedPages from "./compontents/AttachedPages.vue";
import Paging from "./compontents/Paging/index.vue";
import { credit_basic } from "@/api/downWrod";
import saveAs from "../js/FileSaver";
import htmlDocx from "../js/html-docx.js";
import { publicDownPDF2 } from "@/utils.js";
import * as sesstion from "@/store/localStorage";
export default {
  components: {
    First,
    One,
    Two,
    Three,
    Four,
    Five,
    AttachedPages,
    Appendix,
    Paging,
    Stext,
  },
  data() {
    return {
      downWord: {},
      one: {},
      two: {},
      three: {},
      four: {},
      five: {},
      tableList: {},
    };
  },
  created() {
    setTimeout(this.setData.bind(this), 0);
  },
  methods: {
    async setData() {
      let downWord = sessionStorage.downWord;

      if (!downWord) return false;
      this.$loadingShow();
      downWord = JSON.parse(downWord);

      const { data } = await credit_basic(downWord);
      downWord.company_name = data.Data[0].cover.company;
      downWord.report_yeartime = data.Data[0].cover.report_yeartime;
      this.downWord = downWord;

      this.one = Object.freeze(data.Data[1]);
      this.two = Object.freeze(data.Data[2]);
      this.three = Object.freeze(data.Data[3]);

      this.four = Object.freeze(data.Data[4]);
      this.five = Object.freeze(data.Data[5]);
      this.tableList = Object.freeze(data.Data[6]);

      this.$loadingHide();
      setTimeout(() => {
        this.wordDown();
        this.pdfDown();
      }, 2e3);
    },
    wordDown() {
      if (this.$route.query.status != 2) return false;
      setTimeout(() => {
        var contentDocument = document.getElementById("contentBox2");

        var content =
          "<!DOCTYPE html>" +
          `<head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"></head>` +
          contentDocument.outerHTML;
        var converted = htmlDocx.asBlob(content);
        saveAs(
          converted,
          `【专用信贷版分析报告】${this.downWord.company_name}${this.downWord.year}`
        );
        this.xlxDown();
        setTimeout(() => {
          window.parent.dialog.closeLoading(this.$route.query.checkboxGroup);
        }, 1e3);
      }, 4e3);
    },
    pdfDown() {
      if (this.$route.query.status == 0) {
        const { company_name, year } = this.downWord;

        setTimeout(() => {
          publicDownPDF2.call(
            this,
            `【专用信贷版分析报告】${company_name}${year}`,
            "a",
            [{ id: "biao" }]
          );
          this.xlxDown();
          setTimeout(() => {
            window.parent.dialog.closeLoading(this.$route.query.checkboxGroup);
          }, 1e3);
        }, 1500);
      }
    },
    xlxDown() {
      fetch(window.BASE_URL + this.tableList.download_uri)
        .then((response) => response.blob())
        .then((res) => {
          let blod = new Blob([res]);
          saveAs(
            blod,
            `【专用信贷版分析报告】${this.downWord.company_name}${this.downWord.year}附表.xlsx`
          );
        });
    },
  },
};
</script>

<style lang="less">
.biao {
  font-family: "SimSun";
  p {
    line-height: 1.7 !important;
  }
}
</style>