<template>
  <div id="biao">
    <div class="box" style="font-family: SimSun">
      <div id="contentBox2" style="font-family: SimSun">
        <One :first="first"></One>
      </div>
    </div>
  </div>
</template>

<script>
/* 下载word */
import Stext from "./commponts/text.vue";
import One from "./commponts/one.vue";

import { publicDownPDF } from "@/utils.js";
import saveAs from "../js/FileSaver";
import htmlDocx from "../js/html-docx.js";
import { quality } from "@/api/downWrod";

export default {
  components: {
    One,
    Stext,
  },

  data() {
    return {
      downWord: {},
      first: {},

      tableList: {},
    };
  },
  created() {
    setTimeout(this.setData.bind(this), 0);
  },

  methods: {
    async setData() {
      let downWord = JSON.parse(sessionStorage.downWord);
      this.downWord = downWord;
      if (!downWord) return false;
      this.$loadingShow();
      const { data } = await quality(downWord);
      this.first = data.Data;

      this.$loadingHide();
      setTimeout(() => {
        this.wordDown();
        this.pdfDown();
      }, 4e3);
    },
    wordDown() {
      if (this.$route.query.status != 2) return false;
      setTimeout(() => {
        var contentDocument = document.getElementById("biao");
        var content =
          "<!DOCTYPE html>" +
          `<head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"></head>` +
          contentDocument.outerHTML;
        var converted = htmlDocx.asBlob(content);
        saveAs(
          converted,
          `报表质量分析报告${this.downWord.company_name}${this.downWord.year}`
        );

        setTimeout(() => {
          window.parent.dialog.closeLoading(this.$route.query.checkboxGroup);
        }, 1e3);
      }, 4e3);
    },
    pdfDown() {
      if (this.$route.query.status == 0) {
        const { company_name, year } = this.downWord;

        setTimeout(() => {
          publicDownPDF.call(
            this,
            `报表质量分析报告${company_name}${year}`,
            "a",
            [{ id: "biao" }]
          );
          setTimeout(() => {
            window.parent.dialog.closeLoading(this.$route.query.checkboxGroup);
          }, 1e3);
        }, 4e3);
      }
    },
  },
};
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
  line-height: 1;
}
p {
  line-height: 1;
}
#biao {
  .none {
    display: none;
  }
  width: 1200px;
  background: #fff;
  margin: 0 auto;
  padding: 50px 18px;

  > .box {
    width: 100%;
    height: 100%;
    // box-shadow: 0 0 8px #ccc;
    overflow: hidden;
    padding-bottom: 58px;

    > .btn {
      text-align: right;
      // margin-top:20px;
      padding-right: 16px;
      padding-top: 20px;
    }
    .content {
      width: 80%;
      margin: 20px auto;
      page-break-after: always;
      td,
      th {
        padding: 3px 0;
      }

      cursor: default;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        line-height: 1.4;
      }
      p {
        margin-bottom: 10px;
      }
      > h3 {
        text-align: left;
        margin-top: 30px;
      }
      .msg {
        width: 100%;
        padding: 16px 30px;
        p {
          text-align: left;
        }
      }
      > .bar-box {
        width: 100%;
        overflow: hidden;
        margin-top: 30px;
        > div {
        }
        .left-bar {
          float: left;
          width: 60%;
        }
        .right-bar {
          width: 40%;

          float: left;
        }
        .thr-line {
          width: 80%;
          margin: 0 auto;
          height: 259px;
        }
      }
    }
  }
}
</style>
