var render = function render(){var _vm=this,_c=_vm._self._c;return _c('table',{staticStyle:{"color":"#333","width":"100%","background-color":"transparent","border-collapse":"collapse"},attrs:{"border":""}},[_c('thead',_vm._l((_vm.data.header),function(tr){return _c('tr',{key:tr},_vm._l((tr),function(th){return _c('th',{key:th,style:(`font-size:14pt; padding: 10px;border:1px solid ${_vm.borderColor}; font-weight:bold; background: #c7daf1;vertical-align: middle;text-align: center;` +
          th.style),attrs:{"colspan":th.colspan || 1,"rowspan":th.rowspan || 1}},[_vm._v(" "+_vm._s(th.val)+" ")])}),0)}),0),_c('tbody',_vm._l((_vm.data.body),function(tr,i){return _c('tr',{key:i},_vm._l((tr),function(th){return _c('td',{key:th,staticStyle:{"padding":"5px","border":"1px solid #333","font-size":"14pt","line-height":"24px"},attrs:{"colspan":th.colspan || 1,"rowspan":th.rowspan || 1,"width":th.width}},[(th.noLine)?[_c('span',{staticStyle:{"font-size":"10pt"},style:(th.style)},[_vm._v(" "+_vm._s(" ")+" "),_c('br'),_vm._v(" "+_vm._s(" ")+" ")])]:[(_vm.get_status(th.status).img)?_c('img',{attrs:{"src":_vm.get_status(th.status).img}}):_vm._e(),(
              _vm.showLine &&
              (th.status == 3 ||
                th.status == 7 ||
                th.status == '+violent' ||
                th.status == '-violent') &&
              _vm.get_status(th.status).img
            )?_c('img',{attrs:{"src":_vm.get_status(th.status).img}}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(th.val)+" ")])]],2)}),0)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }