<template>
  <div class="content">
    <Stext size="2" ele="h1" align="center" weight="400" top="100" bottom="50">{{ data.title1 }}</Stext>
    <Stext size="10"  ele="p" weight="800" indent="true">{{ data.content[0] }}</Stext>
    <Stext size="10"  ele="p" top="5" indent="true">{{ data.content[1] }}</Stext>
    <Stext size="10"  ele="p" top="5" indent="true">{{ data.content[2] }}</Stext>
    <Stext size="10"  ele="p" top="5" indent="true">{{ data.content[3] }}</Stext>
    <Stext size="10"  ele="p" top="5" indent="true">{{ data.content[4] }}</Stext>
  </div>
</template>

<script>
import Stext from "./text.vue";
export default {
  components: {
    Stext,
  },
  props: {
    data: Object,
  }
}
</script>

<style lang="less" scoped>
.content {
  p {
    line-height: 1.7;
  }
}
</style>