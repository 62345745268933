<template>
  <div class="content">
    <Stext size="6" ele="h1" align="center" weight="400" top="60" bottom="50">{{
      data.title
    }}</Stext>
    <div v-for="(item, index) in data.detail" :key="index">
      <Stext size="2" ele="p" weight="800" top="20">{{ item.title }}</Stext>
      <div v-if="index == 0">
        <Stext
          size="10"
          ele="p"
          indent="true"
          bottom="20"
          v-for="t in item.details"
          :key="t"
          >{{ t.content }}</Stext
        >
        <!-- <STable style="margin-top: 20px;" :data="getTable(item.charts[0].content.details, item.charts[0].content.title)">
        </STable> -->
        <table
          border="1"
          style="
            width: 100%;
            background-color: transparent;
            border-collapse: collapse;
          "
        >
          <thead
            v-for="tr in getTableHeader(item.charts[0].content.title)"
            :key="tr"
          >
            <tr v-for="trs in tr" :key="trs">
              <th
                v-for="th in trs"
                :key="th"
                :colspan="th.colspan || 1"
                :rowspan="th.rowspan || 1"
                :style="
                  `padding: 5px;border:1px solid #333; font-weight:bold; background: #c7daf1;vertical-align: middle;text-align: center;` +
                  th.style
                "
              >
                <s-text
                  ele="p"
                  size="10"
                  weigth="700"
                  :style="'text-align: left;' + th.style"
                  >{{ th.val }}</s-text
                >
              </th>
            </tr>
          </thead>

          <tbody
            v-for="td in getBody(item.charts[0].content.details)"
            :key="td"
          >
            <tr v-for="(tr, i) in td" :key="i">
              <td v-for="th in tr" :key="th" :style="th.style">
                <s-text ele="p" size="10" weigth="700">{{ th.val }}</s-text>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="index == 1">
        <Stext
          size="10"
          ele="p"
          indent="true"
          bottom="20"
          v-for="t in item.details"
          :key="t"
          >{{ t.content }}</Stext
        >
      </div>
      <div v-if="index == 2">
        <Stext
          v-for="(t, i) in item.details[0].content"
          :key="i"
          size="10"
          ele="p"
          indent="true"
          bottom="20"
          >{{ t }}
        </Stext>
        <div id="readChart">
          <Stext size="10" ele="p" indent="true" bottom="20">{{
            item.details[1].content
          }}</Stext>
          <div id="read" ref="read" style="width: 100%; height: 350px"></div>
          <Stext size="10" ele="p" indent="true" bottom="20">{{
            item.details[2].content
          }}</Stext>
          <div id="reads" ref="reads" style="width: 100%; height: 400px"></div>
        </div>

        <Stext size="10" ele="p" indent="true" bottom="20">{{
          item.details[3].content
        }}</Stext>
        <Stext
          v-for="(t, i) in item.details[4].content"
          :key="i"
          size="10"
          ele="p"
          indent="true"
          bottom="20"
          >{{ t }}
        </Stext>
      </div>
    </div>
  </div>
</template>

<script>
import Stext from "./text.vue";
import STable from "./table";
import html2canvas from "html2canvas";
import { whitewash_list_filter_data } from "./filter";
export default {
  components: {
    Stext,
    STable,
  },
  props: {
    data: Object,
  },
  watch: {
    data() {
      setTimeout(() => {
        this.init();
        this.inits();
      }, 500);
    },
  },
  methods: {
    getTableHeader(table_name) {
      const header = [
        [
          {
            val: table_name,
            style: "font-weight: 700;",
            colspan: "3",
            style: "text-align:center",
          },
        ],
        [
          {
            val: "异常指标",
            style: "background: #fff;text-align:left;width:200px",
          },
          // { val: "异常详情及异常描述", style: "background: #fff;" },
          { val: "建议", style: "background: #fff;text-align:left" },
        ],
      ];
      return {
        header,
      };
    },

    getBody(table) {
      const body = [];
      for (let i = 0; i < table.length; i++) {
        body.push([
          {
            val: table[i].title,
            colspan: "1",
            style:
              "text-align:left;width:200px;padding:5px;border:1px solid #333",
          },

          {
            val: table[i].suggestion,
            colspan: "1",
            style: "text-align:left;padding:5px;border:1px solid #333",
          },
        ]);
      }

      return {
        body,
      };
    },
    init() {
      let data = this.data.detail[2].charts[0].content;

      let dataList = [];
      data.map((item) => {
        item.name = item.name + "\n(" + item.compare + ")";
        dataList.push(item.value);
        item.max = 5;
      });
      let echarts = this.$echarts.init(document.getElementById("read"));
      var option = {
        color: "#3A86FD",
        radar: {
          name: {
            color: "#333",
            fontSize: "16px",
          },
          radius: "75%",
          axisName: {
            fontSize: 12,
            color: "#333",
          },
          indicator: data,
        },
        series: [
          {
            type: "radar",
            data: [
              {
                value: dataList,
              },
            ],
          },
        ],
      };
      echarts.setOption(option);
    },
    inits() {
      let data = this.data.detail[2].charts[1].content[0];
      let dataList = this.data.detail[2].charts[1].content[1];
      let arr = [];
      data.map((item) => {
        let obj = {};
        obj.name = item;
        obj.max = 4;
        arr.push(obj);
      });
      let echarts = this.$echarts.init(document.getElementById("reads"));
      var option = {
        color: "#3A86FD",
        radar: {
          name: {
            color: "#333",
            fontSize: "16px",
          },
          radius: "80%",
          axisName: {
            fontSize: 12,
            color: "#333",
          },
          indicator: arr,
        },
        series: [
          {
            type: "radar",
            data: [
              {
                value: dataList,
              },
            ],
          },
        ],
      };
      echarts.setOption(option);
      if (this.$route.query.status == 2) {
        setTimeout(() => {
          this.toImg();
        }, 1000);
      }
    },

    toImg() {
      if (this.$route.query.status == 0) return false;
      const Whitewash = document.querySelector("#readChart");
      html2canvas(Whitewash).then(function (canvas) {
        let pageData = canvas.toDataURL("image/jpeg");
        let img = new Image();
        img.src = pageData;
        img.width = "550";
        img.height = "500";
        Whitewash.innerHTML = "";
        Whitewash.appendChild(img);
      });
    },
    // toImg1() {
    //   const base64 = this.$refs.read.querySelector("canvas").toDataURL();
    //   this.$refs.read.innerHTML = `<img width="50%" height="300" src="${base64}" / >`;
    // },
    // toImg2() {
    //   const base64 = this.$refs.reads.querySelector("canvas").toDataURL();
    //   this.$refs.reads.innerHTML = `<img width="50%" height="300" src="${base64}" / >`;
    // },
  },
};
</script>

<style></style>