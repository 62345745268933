<template>
  <table
    border="1"
    style="
      width: 100%;
      font-size: 14pt;
      color: #333;
      background-color: transparent;
      border-collapse: collapse;
    "
  >
    <thead>
      <tr>
        <th
          style="background: #c7daf1"
          class="th"
          v-for="(tr, i) in data.header"
          :key="i"
        >
          {{ tr.val }}
        </th>
      </tr>
    </thead>

    <tbody>
      <tr v-for="(tr, i) in data.body" :key="i">
        <td
          style="
            vertical-align: middle;
            padding: 5px;
            border: 1px solid #333;
            text-align: left;

            height: 66px;
          "
        >
          {{ tr.name }}
        </td>
        <td
          style="
            vertical-align: middle;
            padding: 5px;
            border: 1px solid #333;
            text-align: right;
            width: 120px;
          "
        >
          {{ tr.value }}
        </td>
        <td
          style="
            vertical-align: middle;
            padding: 5px;
            border: 1px solid #333;
            text-align: right;
            width: 100px;
          "
        >
          {{ tr.rate }}
        </td>
        <td
          style="
            vertical-align: middle;
            padding: 5px;
            border: 1px solid #333;
            text-align: left;

            height: 66px;
          "
        >
          {{ tr.name1 }}
        </td>
        <td
          style="
            vertical-align: middle;
            padding: 5px;
            border: 1px solid #333;
            text-align: right;
            width: 120px;
          "
        >
          {{ tr.value1 }}
        </td>
        <td
          style="
            vertical-align: middle;
            padding: 5px;
            border: 1px solid #333;
            text-align: right;
            width: 100px;
          "
        >
          {{ tr.rate1 }}
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  components: {},
  props: {
    data: Array,
    borderColor: {
      type: String,
      default: "#000",
    },
  },
  data() {
    return {};
  },

  methods: {},
};
</script>
<style scoped>
.th {
  padding: 5px;
  border: 1px solid #333;
  font-weight: bold;
  background: #c7daf1;
  vertical-align: middle;
  text-align: center;
}
</style>
