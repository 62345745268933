<template>
  <div class="content">
    <Stext
      v-for="item in first.cover"
      :key="item"
      size="3"
      ele="h1"
      align="center"
      top="50"
      >{{ item }}</Stext
    >

    <Stext size="6" weight="700" ele="p" top="50" bottom="20">{{
      data.title1
    }}</Stext>

    <Stext
      v-for="(item, index) in data.content"
      :key="index"
      size="9"
      ele="p"
      top="5"
      indent="true"
      >{{ item }}</Stext
    >

    <!-- 第二章 -->
    <Stext size="6" weight="700" ele="p" top="50" bottom="20">{{
      two.title1
    }}</Stext>

    <div
      style="margin-top: 20px"
      v-for="(item, index) in two.content"
      :key="index"
    >
      <Stext size="2" ele="p" top="40" weight="700" bottom="20">
        {{ item.title2 }}</Stext
      >
      <Stext
        v-for="(text, index1) in item.content.detail"
        :key="index1"
        size="9"
        ele="p"
        top="5"
        indent="true"
        >{{ text }}</Stext
      >

      <div v-if="index == 0" style="margin-top: 20px">
        <STable
          style="margin-top: 20px"
          :data="getTable(item.content.盈利能力, '盈利能力')"
        ></STable>
      </div>
      <div v-if="index == 1" style="margin-top: 20px">
        <STable
          style="margin-top: 20px"
          :data="getTables(item.content)"
        ></STable>
      </div>
      <div v-if="index == 2" style="margin-top: 20px">
        <STable
          style="margin-top: 20px"
          :data="getTable(item.content.营运能力, '营运能力')"
        ></STable>
      </div>
      <div v-if="index == 3" style="margin-top: 20px">
        <STable
          style="margin-top: 20px"
          :data="getTable(item.content.发展能力, '发展能力')"
        ></STable>
      </div>
    </div>
  </div>
</template>

<script>
import Stext from "./text.vue";
import STable from "./table.vue";
export default {
  components: {
    Stext,
    STable,
  },
  props: {
    first: Object,
    data: Object,
    two: Object,
  },
  methods: {
    getTable(table, table_name) {
      const header = [
        [
          { val: "指标大类" },
          { val: "指标名称" },
          { val: "本期指标值" },
          { val: "行业优秀值" },
          { val: "行业平均值" },
          { val: "行业较差值" },
          { val: "变动率" },
        ],
      ];
      const body = [];

      Object.keys(table).forEach((key) => {
        table[key].name = table_name;
        table[key].key = key;
        body.push(table[key]);
      });

      for (let i = 0; i < body.length; i++) {
        // 循环开始行
        let startRow;
        // 需合并的行数
        let rowspan = 1;
        // 循环到最后一行时
        if (i === body.length - 1) {
          // 如果最后一行和倒数第二行属性不同，则rowspan=1；否则直接结束循环
          if (body[i].name !== body[i - 1].name) {
            body[i].rowspan = rowspan;
          }
          break;
        }
        // 内层循环记录rowspan的数量
        for (let j = i; j < body.length - 1; j++) {
          // 记录循环结束的行数
          startRow = j;
          // 属性相同则rowspan+1；否则直接结束内循环
          if (body[j].name === body[j + 1].name) {
            rowspan++;
          } else {
            break;
          }
        }
        // 为数组添加rowspan属性
        body[i].rowspan = rowspan;
        // 控制外循环从内循环结束的行数开始
        i = startRow;
      }

      return {
        header,
        body,
      };
    },

    getTables(table) {
      const header = [
        [
          { val: "指标大类" },
          { val: "指标名称" },
          { val: "本期指标值" },
          { val: "行业优秀值" },
          { val: "行业平均值" },
          { val: "行业较差值" },
          { val: "变动率" },
        ],
      ];
      const body = [];

      Object.keys(table.短期偿债能力).forEach((key) => {
        table.短期偿债能力[key].name = "短期偿债能力";
        table.短期偿债能力[key].key = key;
        body.push(table.短期偿债能力[key]);
      });
      Object.keys(table.长期偿债能力).forEach((key) => {
        table.长期偿债能力[key].name = "长期偿债能力";
        table.长期偿债能力[key].key = key;
        body.push(table.长期偿债能力[key]);
      });

      for (let i = 0; i < body.length; i++) {
        // 循环开始行
        let startRow;
        // 需合并的行数
        let rowspan = 1;
        // 循环到最后一行时
        if (i === body.length - 1) {
          // 如果最后一行和倒数第二行属性不同，则rowspan=1；否则直接结束循环
          if (body[i].name !== body[i - 1].name) {
            body[i].rowspan = rowspan;
          }
          break;
        }
        // 内层循环记录rowspan的数量
        for (let j = i; j < body.length - 1; j++) {
          // 记录循环结束的行数
          startRow = j;
          // 属性相同则rowspan+1；否则直接结束内循环
          if (body[j].name === body[j + 1].name) {
            rowspan++;
          } else {
            break;
          }
        }
        // 为数组添加rowspan属性
        body[i].rowspan = rowspan;
        // 控制外循环从内循环结束的行数开始
        i = startRow;
      }

      return {
        header,
        body,
      };
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  p {
    line-height: 1.7;
  }
}
</style>
